import React, { Component, useRef, useState } from "react";

import Breadcrumb from "../../common/breadcrumb";
import { postCall, getCall } from "../../../api/post";
import {
  saveUserDetailsToLocalStorage,
  loadUserDetailsFromLocalStorage,
  getValueFromLanguageObject,
  getColorCodeBasedOnKey,
  isStoreOpen,
} from "../../../store/index";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { connect } from "react-redux";
import {
  getMerchantInformationUrl,
  getMasterDetailsIdUrl,
  getTenantInfoUrl,
} from "../../../services";
import { Helmet } from "react-helmet";
import MainLoader from "../../../animation/MainLoader";
import ErrorMessageAnimationModal from "../../../animation/ErrorMessageAnimationModal";
import SuccessMessageAnimationModal from "../../../animation/SuccessMessageAnimationModal";
import { isUserAllowedToRegister } from "../../../actions";
import { clearLocalStorageReload, navigateTo } from "../../../utils/utils";
import { withRouter } from "react-router-dom";
import { Button } from "antd";

class Login extends Component {
  state = {
    cellnumber: "",
    otp: "",
    isLoginButtonClicked: false,
    userId: "",
    remainingTime: 60,
    isLoading: true,
    showErrorMessageModal: false,
    errorMessage: "",
    showSuccessMessageModal: false,
    successMessage: "",
    masterDetailsObj: {},
    isEmailBasedLogin: false,
    email: "",
    maxCellnumberDigitLimit: "",
    isShowXEModule: false,
  };

  componentDidMount() {
    const userDetails = loadUserDetailsFromLocalStorage();
    if (userDetails !== undefined) {
      navigateTo(this.props, `/`);
      return;
    }

    this.initMasterDetailsId().then(() => {
      const { masterDetailsObj } = this.state;
      this.props.isUserAllowedToRegister(masterDetailsObj);

      this.initMerchantInfo().then(() => {
        this.setState({
          isLoading: false,
        });
      });
      this.initTenantInfo();
    });
  }

  initMasterDetailsId = async () => {
    let masterDetailsObj = {};
    const publicUrl = window.location.pathname.split("/")[1];
    const masterDetailsIdUrl = getMasterDetailsIdUrl(publicUrl);
    const masterDetails = await getCall(masterDetailsIdUrl, []).then();
    if (masterDetails.error) {
      clearLocalStorageReload({ response: masterDetails });
    } else {
      if (masterDetails) {
        masterDetailsObj = masterDetails;
      }
    }
    this.setState({ masterDetailsObj: masterDetailsObj });
  };

  initMerchantInfo = async () => {
    const { masterDetailsObj } = this.state;
    const merchantInfoUrl = getMerchantInformationUrl(masterDetailsObj);
    const merchantInfo = await getCall(merchantInfoUrl, []);
    if (merchantInfo.error) {
      clearLocalStorageReload({ response: merchantInfo });
      this.setState({
        AppTitle: "",
      });
    } else {
      this.setState({
        AppTitle: merchantInfo.companyname,
      });
    }
  };

  initTenantInfo = async () => {
    const { masterDetailsObj } = this.state;
    const { id } = masterDetailsObj || {};
    if (!id) {
      return;
    }
    this.setState({ isLoading: true });
    const tenantInfoUrl = getTenantInfoUrl(id);
    const tenantInfo = await getCall(tenantInfoUrl, []);
    this.setState({ isLoading: false });
    if (tenantInfo.error) {
      clearLocalStorageReload({ response: tenantInfo });
      this.loadErrorMessageAnimation(tenantInfo.error.message);
    } else {
      if (
        tenantInfo &&
        tenantInfo.length > 0 &&
        tenantInfo[0].text &&
        tenantInfo[0].text.length > 0
      ) {
        const tenantSettings = JSON.parse(tenantInfo[0].text);
        if ("isEmailBasedLogin" in tenantSettings) {
          this.setState({
            isEmailBasedLogin: tenantSettings.isEmailBasedLogin,
          });
        }
        if ("maxCellnumberDigitLimit" in tenantSettings) {
          this.setState({
            maxCellnumberDigitLimit: tenantSettings.maxCellnumberDigitLimit,
          });
        } else {
          this.setState({
            maxCellnumberDigitLimit: "10",
          });
        }
        if ("isShowXEModule" in tenantSettings) {
          this.setState({
            isShowXEModule: tenantSettings.isShowXEModule,
          });
        } else {
          this.setState({
            isShowXEModule: false,
          });
        }
      }
    }
  };

  onClickOfMobileLogin = (e) => {
    e.preventDefault();
    const {
      isEmailBasedLogin,
      cellnumber,
      email,
      maxCellnumberDigitLimit,
    } = this.state;
    const maxCellnumberDigitLimitInt = parseInt(maxCellnumberDigitLimit);
    if (
      !isEmailBasedLogin &&
      cellnumber.trim().length !== maxCellnumberDigitLimitInt
    ) {
      this.loadErrorMessageAnimation(
        getValueFromLanguageObject("msg_enter_mobile_number_incorrect")
          ? getValueFromLanguageObject("msg_enter_mobile_number_incorrect")
          : "Please enter correct mobile number"
      );
      return;
    } else if (isEmailBasedLogin) {
      const re = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
      if (!re.test(email)) {
        this.loadErrorMessageAnimation(
          getValueFromLanguageObject("str_please_enter_valid_email_address")
            ? getValueFromLanguageObject("str_please_enter_valid_email_address")
            : "Please Enter Valid Email Address."
        );
        return;
      }
    }
    this.postLoginApi();
  };

  postLoginApi = async () => {
    const {
      masterDetailsObj,
      isEmailBasedLogin,
      email,
      cellnumber,
    } = this.state;
    if (!masterDetailsObj.id) {
      return;
    }

    const req = {
      ...(!isEmailBasedLogin && { cellnumber }),
      ...(isEmailBasedLogin && { email }),
      devicetoken: "",
      masterdetailId: masterDetailsObj.id,
      roleId: 2,
    };

    this.setState({ isLoading: true }, async () => {
      const login = await postCall("users/login", req);
      this.setState({ isLoading: false });
      if (login.error) {
        clearLocalStorageReload({ response: login });
        this.loadErrorMessageAnimation(login.error.message);
        this.setState({ isLoginButtonClicked: false });
      } else {
        if (login && login.userId != null) {
          const isOpenStore = isStoreOpen();
          if (isOpenStore === "false") {
            localStorage.setItem("apiToken", login.apitoken);
          }
          this.setState(
            {
              userId: login.userId,
              apiToken: login.apitoken,
              isLoginButtonClicked: true,
            },
            async () => {
              this.setState({ isLoading: true }, () => {
                this.getOtp().then(() => {
                  this.setState({ isLoading: false });
                });
              });
            }
          );
        }
      }
    });
  };

  getOtp = async () => {
    const { email, cellnumber, isEmailBasedLogin, userId } = this.state;
    const getOtpUrl = `usermetaauths/sendOtp?userId=${userId}&receiverType=SMS&type=signinotp`;
    const getOtp = await getCall(getOtpUrl, []);
    let otpString = getValueFromLanguageObject("str_otp_desc")
      ? getValueFromLanguageObject("str_otp_desc")
      : "A OTP(One Time Password) has sent to";
    let space = " ";
    otpString = otpString.concat(space);
    if (getOtp.error) {
      clearLocalStorageReload({ response: getOtp });
      this.loadErrorMessageAnimation(getOtp.error.message);
    } else {
      const otpPhoneOrEmailDesc = isEmailBasedLogin ? email : cellnumber;
      this.loadSuccessMessageAnimation(
        (otpString = otpString.concat(otpPhoneOrEmailDesc))
      );
    }
  };

  verifyOtp = async () => {
    const { isShowXEModule } = this.state;
    const verifyOtpUrl = "usermetaauths/verifyOtp";
    const verifyOtpObj = {
      signinotp: this.state.otp,
      userId: this.state.userId,
      devicetoken: "",
    };
    const verifyOtp = await postCall(verifyOtpUrl, verifyOtpObj);
    if (verifyOtp.error) {
      clearLocalStorageReload({ response: verifyOtp });
      this.loadErrorMessageAnimation(verifyOtp.error.message);
    } else {
      // store all the user data to localstorage
      localStorage.removeItem("isStoreOpen");
      localStorage.removeItem("openStoreId");
      localStorage.setItem("apiToken", verifyOtp.apitoken);
      saveUserDetailsToLocalStorage(verifyOtp);
      const { isFromCheckout, isFromDashboard } = this.props.match.params || {};
      const { isFromCollection } = this.props.match.params || {};

      if (isShowXEModule) {
        window.location.reload();
        this.props.history.push("/dashboard");
      } else {
        this.props.history.push("/");
      }

      if (isFromCheckout === "true") {
        this.props.history.push("/checkout");
        window.location.reload();
      } else if (isFromDashboard) {
        this.props.history.push("/");
        window.location.reload();
      } else {
        this.props.history.push("/");
      }
      const { path, params } = this.props.match || {};
      const { id } = params || {};
      if (isFromCollection === "true") {
        this.props.history.push(`/collection/${id}`);
        window.location.reload();
      } else {
        this.props.history.push("/");
      }
    }
  };

  resendOtp = () => {
    this.setState({ isLoading: true }, () => {
      this.getOtp().then(() => {
        this.setState({ isLoading: false });
      });
    });
  };

  onClickOfOTPLogin = (e) => {
    e.preventDefault();
    if (this.state.otp.trim().length === 0) {
      this.loadErrorMessageAnimation(
        getValueFromLanguageObject("alert_invalide_otp")
          ? getValueFromLanguageObject("alert_invalide_otp")
          : "OTP not receive proper."
      );
    } else {
      this.verifyOtp();
    }
  };

  handleChangeOfCellNumber = (event) => {
    this.setState({ cellnumber: event.target.value });
  };

  handleChangeOfEmail = (event) => {
    this.setState({ email: event.target.value });
  };

  handleChangeOfOTPNumber = (event) => {
    this.setState({ otp: event.target.value });
  };

  onClickOfResendOtp = () => {
    this.setState({ remainingTime: 60 });
    this.resendOtp();
  };

  onClickOfChangeMobileNumber = () => {
    this.setState({ isLoginButtonClicked: false });
  };

  renderTime = ({ remainingTime }) => {
    const currentTime = useRef(remainingTime);
    const prevTime = useRef(null);
    const isNewTimeFirstTick = useRef(false);
    const [, setOneLastRerender] = useState(0);

    if (currentTime.current !== remainingTime) {
      isNewTimeFirstTick.current = true;
      prevTime.current = currentTime.current;
      currentTime.current = remainingTime;
      this.setState({ remainingTime });
    } else {
      isNewTimeFirstTick.current = false;
    }

    // force one last re-render when the time is over to tirgger the last animation
    if (remainingTime === 0) {
      setTimeout(() => {
        setOneLastRerender((val) => val + 1);
      }, 20);
    }

    const isTimeUp = isNewTimeFirstTick.current;

    return (
      <div className="time-wrapper">
        <div key={remainingTime} className={`time ${isTimeUp ? "up" : ""}`}>
          {remainingTime}
        </div>
      </div>
    );
  };

  loadErrorMessageAnimation = (message) => {
    this.setState({ errorMessage: message, showErrorMessageModal: true });
  };

  loadSuccessMessageAnimation = (message) => {
    this.setState({ successMessage: message, showSuccessMessageModal: true });
  };

  render() {
    const {
      isLoginButtonClicked,
      isLoading,
      AppTitle,
      showErrorMessageModal,
      errorMessage,
      showSuccessMessageModal,
      successMessage,
      isEmailBasedLogin,
      isShowXEModule,
    } = this.state;
    const { isRegisterAllow } = this.props;
    return (
      <>
        <div>
          {/*SEO Support*/}
          <Helmet>
            <title>{AppTitle} | Login</title>
            <meta
              name="description"
              content="Multikart – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Multikart Bootstrap 4 Template will help you run multiple businesses."
            />
          </Helmet>
          {/*SEO Support End */}
          {!isShowXEModule && (
            <Breadcrumb
              title={
                getValueFromLanguageObject("str_login")
                  ? getValueFromLanguageObject("str_login")
                  : "Login"
              }
            />
          )}

          {/*Login section*/}
          <section className="login-page section-b-space">
            <div className="container">
              <div className="row row-flex justify-content-md-center">
                <div className="col-lg-6">
                  <h3>
                    {getValueFromLanguageObject("str_login")
                      ? getValueFromLanguageObject("str_login")
                      : "Login"}
                  </h3>
                  <div className="theme-card" style={{ position: "relative" }}>
                    {!isLoginButtonClicked && (
                      <form
                        className="theme-form"
                        onSubmit={(e) => {
                          this.onClickOfMobileLogin(e);
                        }}
                      >
                        <div className="form-group">
                          {isEmailBasedLogin ? (
                            <>
                              <label htmlFor="email">
                                {getValueFromLanguageObject("str_email_address")
                                  ? getValueFromLanguageObject(
                                      "str_email_address"
                                    )
                                  : "Email Address"}
                              </label>
                              <input
                                id="email"
                                type="email"
                                onChange={this.handleChangeOfEmail}
                                className="form-control"
                                value={this.state.email}
                                placeholder={
                                  getValueFromLanguageObject(
                                    "str_enter_your_email_address"
                                  )
                                    ? getValueFromLanguageObject(
                                        "str_enter_your_email_address"
                                      )
                                    : "Enter your email address"
                                }
                                required={true}
                                // pattern="/^\?([0-9]{10})$/"
                              />
                            </>
                          ) : (
                            <>
                              <label htmlFor="cellnumber">
                                {getValueFromLanguageObject("mobile_number")
                                  ? getValueFromLanguageObject("mobile_number")
                                  : "Mobile number"}
                              </label>
                              <input
                                id="cellnumber"
                                type="tel"
                                onChange={this.handleChangeOfCellNumber}
                                className="form-control"
                                value={this.state.cellnumber}
                                placeholder={
                                  getValueFromLanguageObject(
                                    "str_enter_your_mobile_number"
                                  )
                                    ? getValueFromLanguageObject(
                                        "str_enter_your_mobile_number"
                                      )
                                    : "Enter your mobile number"
                                }
                                required={true}
                              />
                            </>
                          )}
                        </div>

                        <Button
                          type="submit"
                          onClick={(e) => {
                            this.onClickOfMobileLogin(e);
                          }}
                          className="btn btn-solid"
                        >
                          {getValueFromLanguageObject("str_login")
                            ? getValueFromLanguageObject("str_login")
                            : "Login"}
                        </Button>
                      </form>
                    )}

                    {isLoginButtonClicked && (
                      <form
                        onSubmit={(e) => {
                          this.onClickOfOTPLogin(e);
                        }}
                        className="theme-form"
                        style={{ position: "relative" }}
                      >
                        <div className="form-group">
                          <label htmlFor="otp">
                            {getValueFromLanguageObject("str_otp")
                              ? getValueFromLanguageObject("str_otp")
                              : "OTP"}
                          </label>
                          {/* <OtpInput
                            className="otp-field"
                            value={this.state.otp}
                            onChange={this.handleChangeOfOTPNumber}
                            autoFocus
                            OTPLength={4}
                            otpType="number"
                            disabled={false}
                            secure
                            // separator={<span>-</span>}
                            htmlAttrs={{
                              autocomplete: 'off',
                              name: 'number'
                        }}
                        isInputSecure
                            inputStyle={{
                              width: "0.625rem",
                              height: "1rem",
                              marginTop: "1rem",
                              marginBottom: "1rem",
                              marginLeft: "5px",
                              marginRight: "5px",
                              fontSize: "1rem",
                              // borderRadius: 7,
                              border: "2px solid rgba(0,0,0,0.3)",
                            }} /> */}
                          <input
                            type="number"
                            className="form-control"
                            id="otp"
                            onChange={this.handleChangeOfOTPNumber}
                            placeholder={
                              getValueFromLanguageObject("str_otp_title")
                                ? getValueFromLanguageObject("str_otp_title")
                                : "Please enter the OTP to verify your account"
                            }
                            required={true}
                          />
                        </div>
                        <div
                          onClick={(e) => {
                            this.onClickOfOTPLogin(e);
                          }}
                          className="btn btn-solid"
                        >
                          {getValueFromLanguageObject("str_login")
                            ? getValueFromLanguageObject("str_login")
                            : "Login"}
                        </div>
                        <div>
                          <div className="pull-right otp-loader mt-2 mb-3">
                            {this.state.remainingTime !== 0 && (
                              <CountdownCircleTimer
                                size={50}
                                isPlaying
                                duration={this.state.remainingTime}
                                colors={[
                                  ["#004777", 0.33],
                                  ["#F7B801", 0.33],
                                  ["#A30000"],
                                ]}
                              >
                                {this.renderTime}
                              </CountdownCircleTimer>
                            )}
                          </div>
                          {this.state.remainingTime === 0 && (
                            <h6 className="resend-click">
                              {getValueFromLanguageObject(
                                "str_dont_receive_otp_with_question_mark"
                              )
                                ? getValueFromLanguageObject(
                                    "str_dont_receive_otp_with_question_mark"
                                  )
                                : "Don't receive OTP? "}

                              <span
                                className="resend-otp"
                                onClick={() => {
                                  this.onClickOfResendOtp();
                                }}
                                style={{
                                  marginTop: "0px",
                                  color: "#ff4c3b",
                                  cursor: "pointer",
                                }}
                              >
                                {getValueFromLanguageObject("str_resend_otp")
                                  ? getValueFromLanguageObject("str_resend_otp")
                                  : " Resend OTP"}
                              </span>
                            </h6>
                          )}
                        </div>
                        <div
                          className="pull-right cursor-pointer"
                          onClick={() => {
                            this.onClickOfChangeMobileNumber();
                          }}
                        >
                          {isEmailBasedLogin
                            ? "Change Email Address"
                            : getValueFromLanguageObject(
                                "str_change_mobile_number"
                              )
                            ? getValueFromLanguageObject(
                                "str_change_mobile_number"
                              )
                            : "Change Mobile Number"}
                        </div>
                      </form>
                    )}
                  </div>
                </div>
                {isRegisterAllow && (
                  <div className="col-lg-6 right-login">
                    <h3>
                      {getValueFromLanguageObject("str_new_customer")
                        ? getValueFromLanguageObject("str_new_customer")
                        : "New Customer"}
                    </h3>
                    <div className="theme-card authentication-right">
                      <h6 className="title-font">
                        {getValueFromLanguageObject("str_create_new_account")
                          ? getValueFromLanguageObject("str_create_new_account")
                          : "Create an Account"}
                      </h6>
                      <p>
                        {getValueFromLanguageObject(
                          "str_create_new_account_desc"
                        )
                          ? getValueFromLanguageObject(
                              "str_create_new_account_desc"
                            )
                          : "Sign up for a free account at our store. Registration is quick and easy. It allows you to be able to order from our shop. To start shopping click 'create an account'."}
                      </p>
                      <button
                        onClick={() => {
                          const { isFromCheckout } =
                            this.props.match.params || {};
                          const signupPageUrl =
                            isFromCheckout === "true"
                              ? `/signup/isFromCheckout=${true}`
                              : `/signup`;
                          this.props.history.push(signupPageUrl);
                        }}
                        className="btn btn-solid"
                      >
                        {getValueFromLanguageObject("str_create_new_account")
                          ? getValueFromLanguageObject("str_create_new_account")
                          : "Create an Account"}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </section>

          {isLoading && <MainLoader />}
        </div>
        {showErrorMessageModal && (
          <ErrorMessageAnimationModal
            show={showErrorMessageModal}
            message={errorMessage}
            onClose={() => {
              this.setState({ showErrorMessageModal: false });
            }}
          />
        )}
        {showSuccessMessageModal && (
          <SuccessMessageAnimationModal
            show={showSuccessMessageModal}
            message={successMessage}
            onClose={() => {
              this.setState({ showSuccessMessageModal: false });
            }}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  isRegisterAllow: state.settings.isRegisterAllow,
});

export default connect(mapStateToProps, {
  isUserAllowedToRegister: isUserAllowedToRegister,
})(withRouter(Login));
