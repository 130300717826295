import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import EllipsisText from "react-ellipsis-text";
import { connect } from "react-redux";
import { isInquiryOrCart } from "../../../actions";
import {
  getCurrencySymbol,
  getColorCodeBasedOnKey,
  getValueFromLanguageObject,
} from "../../../store";
import ImageWrapper from "../../layouts/common/ImageWrapper";
import Moment from "react-moment";

class WishProductListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      showErrorMessageModal: false,
      errorMessage: "",
    };
  }

  componentDidMount() {}

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  getImageNameFromProduct = (product) => {
    const { product: productItem } = product || {};
    const { productmedia } = productItem || {};
    const [productmediaItem] = productmedia || [];
    const { productname: name } = productmediaItem || {};
    return name ? name : "";
  };

  render() {
    const {
      product,
      symbol,
      isInquiry,
      onAddToCartClicked,
      isVisibleDeleteWishlist,
    } = this.props;
    const { product: productItem } = product || {};
    const { name: productName = "", price: productPrice, created } =
      productItem || {};

    const strItemAdded =
      getValueFromLanguageObject("str_item_added") || "Item Added";

    const strAddToInquiry =
      getValueFromLanguageObject("str_add_to_inquiry") || "Add To Inquiry";

    const strAddToCart =
      getValueFromLanguageObject("str_add_to_cart") || "Add to cart";

    const strDelete = getValueFromLanguageObject("str_delete") || "Delete";

    return (
      <>
        <div className="product-box">
          <div className="wishlist-product">
            <div className="img-wrapper mb-2">
              <div className="front">
                <Link to={`/singleproduct/${product.id}`}>
                  <div style={{ height: "250px" }}>
                    <ImageWrapper
                      imgUrl={this.getImageNameFromProduct(product)}
                    />
                  </div>
                </Link>
              </div>
            </div>
            <div className="product-detail">
              <div>
                <Link to={`/singleproduct/${product.id}`}>
                  <h6
                    className="mb-2"
                    style={{
                      color: getColorCodeBasedOnKey(
                        "text_product_name_products"
                      ),
                      marginBottom: "3px",
                    }}
                  >
                    <EllipsisText text={productName} length={35} />
                  </h6>
                </Link>

                {!isInquiry && (
                  <h4 className="mb-3">{`${symbol} ${
                    productPrice === null
                      ? "0"
                      : parseFloat(productPrice).toFixed(2)
                  }`}</h4>
                )}
                <div className="mb-2 added-date">
                  {strItemAdded} <Moment format="DD-MMM-YYYY">{created}</Moment>
                </div>
              </div>
            </div>
          </div>
          <div className="product_box_button">
            <button
              className="btn btn-solid"
              style={{
                marginRight: "13px",
              }}
              onClick={() => {
                onAddToCartClicked(productItem, 1);
              }}
            >
              {isInquiry ? strAddToInquiry : strAddToCart}
            </button>
            {isVisibleDeleteWishlist && (
              <button
                className="btn btn-danger"
                onClick={() => {
                  this.props.onClickOfDeleteWishlist(product);
                }}
              >
                {strDelete}
              </button>
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  isInquiry: state.settings.isInquiry,
  symbol: getCurrencySymbol(),
  masterDetailsObj: state.masterDetails.masterDetailsObj,
});

export default connect(
  mapStateToProps,
  { isInquiryOrCart: isInquiryOrCart }
)(withRouter(WishProductListItem));
