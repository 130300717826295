import { MASTER_DETAILS_ID } from "../constants/ActionTypes";
const initialState = {
  masterDetailsObj: null,
};

const masterDetailsIdReducer = (state = initialState, action) => {
  switch (action.type) {
    case MASTER_DETAILS_ID:
      return { ...state, masterDetailsObj: action.masterDetailsObj };

    default:
      return state;
  }
};
export default masterDetailsIdReducer;
