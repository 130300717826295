import React, { Component } from "react";
import "./wishlist.css";
import WishProductListing from "../collection/common/wish-product-listing";
import WishFilterBar from "../collection/common/wish-filter-bar";
import {
  getCommonCounter,
  getMasterDetailsId,
  getNotificationCounter,
  getUpdateQuantity,
  getWishlist,
  isInquiryOrCart,
} from "../../actions";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  getOpenStoreIdApp,
  getValueFromLanguageObject,
  loadUserDetailsFromLocalStorage,
} from "../../store";
import EditWishListModal from "./EditWishListModal";
import {
  clearLocalStorageReload,
  getWishListId,
  getWishListName,
  isOpenStoreIdExist,
  navigateTo,
  openLinkInNewTab,
  removeUserDetailAndOtherDetails,
} from "../../utils/utils";
import { deleteCall, getCall, postCall } from "../../api/post";
import { getWishListProductUrl } from "../../services";
import ErrorMessageAnimationModal from "../../animation/ErrorMessageAnimationModal";
import NoSpecialProductFoundAnim from "../../animation/NoSpecialProductFoundAnim";
import ProductLoaderMain from "../layouts/contentloader/product-loader";
import { confirmAlert } from "react-confirm-alert";
import DeleteAnim from "../../animation/AnimFiles/DeleteAnim";
import ConfirmAlertPopup from "../../Alert/ConfirmAlertPopup";
import SuccessMessageAnimationModal from "../../animation/SuccessMessageAnimationModal";
import MainLoader from "../../animation/MainLoader";
import AddToCartAnim from "../../animation/AddToCartAnim";
import ShareWishListModal from "./ShareWishListModal";

class Wishlist extends Component {
  state = {
    wishListProduct: [],
    sortingOrder: 0,
    showErrorMessageModal: false,
    errorMessage: "",
    showSuccessMessageModal: false,
    successMessage: "",
    skip: 0,
    limit: 20,
    isContentLoaderVisible: false,
    isShareWishListOpen: false,
  };

  componentDidMount() {
    const userDetails = loadUserDetailsFromLocalStorage();
    const { id } = userDetails || {};
    const { wishlist, masterDetailsObj } = this.props || {};
    if (id && !wishlist) {
      this.props.getWishlist(id);
    }
    if (!masterDetailsObj) {
      this.props.initMasterDetailsId();
    }
    if (wishlist) {
      this.initWishListProducts();
    }
  }

  componentDidUpdate(prevProps) {
    const { wishlist: prevWishlist } = prevProps || {};
    const { wishlist, isInquiry, masterDetailsObj } = this.props || {};
    if (prevWishlist !== wishlist) {
      this.initWishListProducts();
    }
    if (!isInquiry && masterDetailsObj) {
      this.props.isInquiryOrCart(masterDetailsObj);
    }
  }

  initWishListProducts = async () => {
    try {
      this.setState({ isContentLoaderVisible: true });
      const { wishlist } = this.props || {};
      const { wishListProduct, searchValue, sortingOrder, limit, skip } =
        this.state || {};
      const wishListId = getWishListId(wishlist);
      const wishListProductUrl = getWishListProductUrl(
        wishListId,
        searchValue,
        sortingOrder,
        skip,
        limit
      );
      const wishListProductResponse = await getCall(wishListProductUrl, []);
      this.setState({ isContentLoaderVisible: false });
      const { error, data } = wishListProductResponse || {};
      if (error) {
        clearLocalStorageReload({ response: wishListProductResponse });
        const { message, statusCode } = error || {};
        this.loadErrorMessageAnimation(message);
        if (statusCode === 401) {
          removeUserDetailAndOtherDetails();
          navigateTo(this.props, `/login`);
          return;
        }
      } else {
        if (data && limit <= data.length) {
          this.setState({ isPaginationEnableForProductList: true });
        } else {
          this.setState({ isPaginationEnableForProductList: false });
        }
        if (skip > 0) {
          const pl = wishListProduct.concat(data);
          this.setState({ wishListProduct: pl });
        } else {
          this.setState({ wishListProduct: data });
        }
      }
    } catch (error) {
      console.log("error", error);
      this.setState({ isContentLoaderVisible: false });
    }
  };

  onSearchProduct = (value) => {
    this.setState(
      {
        searchValue: value,
        skip: 0,
        productList: [],
      },
      () => {
        this.initWishListProducts();
      }
    );
  };

  handleSortingProductFilter = (order) => {
    this.setState(
      {
        sortingOrder: order,
        skip: 0,
        isContentLoaderVisible: true,
        wishListProduct: [],
      },
      () => {
        this.initWishListProducts().then(() => {
          this.setState({ isLoading: false });
        });
      }
    );
  };

  LayoutViewClicked = () => {};

  openFilter = () => {
    document.querySelector(".collection-filter").style = "left: -15px";
  };

  handlePagination = () => {
    const { wishListProduct } = this.state || {};
    const skip = wishListProduct.length;
    this.setState({ skip }, () => {
      this.initWishListProducts();
    });
  };

  addToCart = async (product, qty) => {
    if (qty === "") {
      return;
    }
    const { isInquiry, counter, updateQuantity } = this.props;
    try {
      if (
        product &&
        product.productvariation &&
        product.productvariation !== null &&
        product.productvariation !== "[]"
      ) {
        navigateTo(this.props, `/singleproduct/${product.id}`);
        return;
      }
      this.setState({ isLoading: true });
      let userDetails = loadUserDetailsFromLocalStorage();
      if (!isOpenStoreIdExist() && userDetails === undefined) {
        return;
      } else {
        userDetails = userDetails === undefined ? {} : userDetails;
      }
      const { id, cityId } = userDetails;
      const openStoreId = getOpenStoreIdApp();
      let userId = openStoreId ? openStoreId : id;

      const addtocartUrl = isInquiry
        ? "orders/productInquiry"
        : "orders/addtocart";
      var addtocartObj = {
        orderstatus: 1,
        inshoppingcart: 1,
        orderdetails: [
          {
            quantity: qty,
            productId: product.id,
          },
        ],
        userId,
        cityId: cityId,
        date: new Date(),
      };
      if (!isInquiry) {
        const totalAmountObj = { totalamount: product.price * parseFloat(qty) };
        addtocartObj = { ...addtocartObj, ...totalAmountObj };
      }
      const addToCartResponse = await postCall(addtocartUrl, addtocartObj);
      this.setState({ isLoading: false });
      if (addToCartResponse.error) {
        clearLocalStorageReload({ response: addToCartResponse });
        this.loadErrorMessageAnimation(addToCartResponse.error.message);
        if (addToCartResponse.error.statusCode === 401) {
          removeUserDetailAndOtherDetails();
          navigateTo(this.props, `/login`);
          return;
        }
      } else {
        if (isInquiry) {
          const strAddToInquirySuccessfully =
            getValueFromLanguageObject("str_add_to_inquiry_successfully") ||
            "Product inquiry added successfully";
          this.loadAddToCartMessageAnimation(strAddToInquirySuccessfully);
        } else {
          const strAddToCartSuccessfully =
            getValueFromLanguageObject("str_add_to_cart_successfully") ||
            "Product added to cart successfully";
          this.loadAddToCartMessageAnimation(strAddToCartSuccessfully);
        }
      }
      counter();
      updateQuantity();
    } catch (error) {
      this.setState({ isLoading: false });
    }
  };

  handleClickOfQuickView = () => {};

  removeProductFromWishlist = async (product, index) => {
    const removeProductFromWishlistUrl = "wishlistdetails/" + product.id;
    this.setState({ isLoading: true });
    const removeProduct = await deleteCall(removeProductFromWishlistUrl);
    this.setState({ isLoading: false });
    if (removeProduct.error) {
      clearLocalStorageReload({ response: removeProduct });
      this.loadErrorMessageAnimation(removeProduct.error.message);
      if (
        removeProduct &&
        removeProduct.error &&
        removeProduct.error.statusCode &&
        removeProduct.error.statusCode === 401
      ) {
        this.removeUserDetailAndRedirectToLogin();
        return;
      }
    } else {
      const strProductHasBeenRemovedSuccessfully =
        getValueFromLanguageObject(
          "str_product_has_been_removed_successfully"
        ) || "Product has been removed successfully!";
      this.loadSuccessMessageAnimation(strProductHasBeenRemovedSuccessfully);
      this.setState({ isLoading: true, skip: 0, productList: [] }, () => {
        this.initWishListProducts().then(() => {
          this.setState({ isLoading: false });
        });
      });
    }
  };

  onClickOfDeleteWishlist = (product) => {
    const strRemoveProduct =
      getValueFromLanguageObject("str_title_remove_product") ||
      "Remove Product";

    const strNo = getValueFromLanguageObject("str_no") || "No";

    const strYesRemoveIt =
      getValueFromLanguageObject("str_yes_remove_it") || "Yes, Remove it!";

    confirmAlert({
      customUI: ({ onClose }) => {
        const submitClick = (props) => {
          this.setState({ isLoading: true }, () => {
            this.removeProductFromWishlist(product).then(() => {
              this.setState({ isLoading: false });
            });
            onClose();
          });
        };
        return (
          <>
            <ConfirmAlertPopup
              title={strRemoveProduct}
              message={
                getValueFromLanguageObject("str_remove_product_cart")
                  ? getValueFromLanguageObject("str_remove_product_cart")
                  : "Are you sure you want to remove this product?"
              }
              animation={<DeleteAnim />}
              submit={strYesRemoveIt}
              submitClick={submitClick}
              cancelClick={onClose}
              cancel={strNo}
            />
          </>
        );
      },
    });
  };

  showEditWishListModal = () => {
    this.setState({ isEditWishListNameOpen: true });
  };

  hideEditWishListModal = () => {
    this.setState({ isEditWishListNameOpen: false });
  };

  showShareWishListModal = () => {
    this.setState({ isShareWishListOpen: true });
  };

  hideShareWishListModal = () => {
    this.setState({ isShareWishListOpen: false });
  };

  onCloseEditWishListNameModal = () => {
    this.hideEditWishListModal();
  };

  onCloseShareWishListModal = () => {
    this.hideShareWishListModal();
  };

  loadErrorMessageAnimation = (message) => {
    this.setState({ errorMessage: message, showErrorMessageModal: true });
  };

  loadSuccessMessageAnimation = (message) => {
    this.setState({ successMessage: message, showSuccessMessageModal: true });
  };

  loadAddToCartMessageAnimation = (message) => {
    this.setState({
      addToCartMessage: message,
      showAddToCartMessageModal: true,
    });
  };

  render() {
    const {
      isEditWishListNameOpen,
      isShareWishListOpen,
      wishListProduct,
      showErrorMessageModal,
      errorMessage,
      isPaginationEnableForProductList,
      limit,
      isContentLoaderVisible,
      showSuccessMessageModal,
      successMessage,
      isLoading,
      showAddToCartMessageModal,
      addToCartMessage,
    } = this.state || {};
    const { wishlist } = this.props || {};

    return (
      <>
        <div className="collection-content">
          <div
            style={{ paddingTop: "70px", paddingBottom: "70px" }}
            className="collection-content container col"
          >
            <div className="page-main-content ">
              <div className="">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="top-banner-wrapper" />
                    <div className="collection-product-wrapper">
                      <div className="wishlist-header">
                        <div className="wishlist-title">
                          <h4>{getWishListName(wishlist)}</h4>
                          <i
                            class="fa fa-edit"
                            onClick={() => {
                              this.showEditWishListModal();
                            }}
                          />
                        </div>
                        <div className="wishlist-share">
                          <ul>
                            <li>
                              <i
                                class="fa fa-share-alt"
                                onClick={() => {
                                  this.showShareWishListModal();
                                }}
                              />
                            </li>
                            <li>
                              <i
                                class="fa fa-print"
                                onClick={() => {
                                  const path = window.location.href.replace(
                                    "wishlist",
                                    "wishlistprintview"
                                  );
                                  openLinkInNewTab(path);
                                }}
                              />
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="product-top-filter">
                        <div className="container-fluid p-0">
                          <div className="row">
                            <div className="col-12">
                              <WishFilterBar
                                productList={wishListProduct}
                                handleSortingProductFilter={
                                  this.handleSortingProductFilter
                                }
                                onLayoutViewClicked={(colmuns) =>
                                  this.LayoutViewClicked(colmuns)
                                }
                                onSearchProduct={this.onSearchProduct}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      {wishListProduct.length > 0 && (
                        <WishProductListing
                          productList={wishListProduct}
                          colSize={3}
                          handlePagination={this.handlePagination}
                          limit={limit}
                          isPaginationEnableForProductList={
                            isPaginationEnableForProductList
                          }
                          addToCart={this.addToCart}
                          handleClickOfQuickView={this.handleClickOfQuickView}
                          onClickOfDeleteWishlist={this.onClickOfDeleteWishlist}
                          isVisibleDeleteWishlist={true}
                        />
                      )}

                      {isContentLoaderVisible && <ProductLoaderMain />}

                      {wishListProduct.length === 0 &&
                        !isContentLoaderVisible && (
                          <NoSpecialProductFoundAnim />
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {isLoading && <MainLoader />}
        {isEditWishListNameOpen && (
          <EditWishListModal
            wishlist={wishlist}
            isEditWishListNameOpen={isEditWishListNameOpen}
            onCloseEditWishListNameModal={() => {
              this.onCloseEditWishListNameModal();
            }}
            onSuccessEditWishListNameModal={() => {
              this.onCloseEditWishListNameModal();
              const userDetails = loadUserDetailsFromLocalStorage();
              const { id } = userDetails || {};
              if (id) {
                this.props.getWishlist(id);
              }
            }}
          />
        )}
        {isShareWishListOpen && (
          <ShareWishListModal
            wishlist={wishlist}
            isShareWishListOpen={isShareWishListOpen}
            onCloseShareWishListModal={() => {
              this.onCloseShareWishListModal();
            }}
          />
        )}
        {showErrorMessageModal && (
          <ErrorMessageAnimationModal
            show={showErrorMessageModal}
            message={errorMessage}
            onClose={() => {
              this.setState({ showErrorMessageModal: false });
            }}
          />
        )}

        {showSuccessMessageModal && (
          <SuccessMessageAnimationModal
            show={showSuccessMessageModal}
            message={successMessage}
            onClose={() => {
              this.setState({ showSuccessMessageModal: false });
            }}
          />
        )}
        {showAddToCartMessageModal && (
          <AddToCartAnim
            show={showAddToCartMessageModal}
            message={addToCartMessage}
            onClose={() => {
              this.setState({ showAddToCartMessageModal: false });
            }}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  wishlist: state.settings.wishlist,
  isInquiry: state.settings.isInquiry,
});

export default connect(
  mapStateToProps,
  {
    counter: getCommonCounter,
    notificationCounter: getNotificationCounter,
    isInquiryOrCart: isInquiryOrCart,
    updateQuantity: getUpdateQuantity,
    getWishlist: getWishlist,
    initMasterDetailsId: getMasterDetailsId,
  }
)(withRouter(Wishlist));
