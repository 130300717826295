import MainLoader from "../../../animation/MainLoader";
import ErrorMessageAnimationModal from "../../../animation/ErrorMessageAnimationModal";
import SuccessMessageAnimationModal from "../../../animation/SuccessMessageAnimationModal";
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import Breadcrumb from "../../common/breadcrumb";
import {
  loadUserDetailsFromLocalStorage,
  getValueFromLanguageObject,
  getCurrencySymbol,
  getColorCodeBasedOnKey,
} from "../../../store";
import { getCall, patchCall } from "../../../api/post";
import {
  getImageUrl,
  getPlaceHolderImage,
  getSettingUrl,
  getShippingOptionsUrl,
  getMerchantInformationUrl,
  getOrderStatusUrl,
  getMasterDetailsIdUrl,
  getBarcodeSetting,
} from "../../../services";
import {
  getNotificationCounter,
  getCommonCounter,
  isInquiryOrCart,
} from "../../../actions";

import { confirmAlert } from "react-confirm-alert";
import DeleteAnim from "../../../animation/AnimFiles/DeleteAnim";
import NoProductFoundEditOrderAnim from "../../../animation/AnimFiles/NoProductFoundEditOrderAnim";
import ConfirmAlertPopup from "../../../Alert/ConfirmAlertPopup";
import {
  clearLocalStorageReload,
  isOpenStoreIdExist,
  navigateTo,
  removeUserDetailAndOtherDetails,
} from "../../../utils/utils";
import { debuglogger } from "../../../utils/logger";
import Lightbox from "react-image-lightbox";

class EditOrder extends Component {
  state = {
    singleOrder: {},
    orderDetails: [],
    orderId: this.props.match.params.id,
    isLoading: true,
    shippingCharges: [],
    isMerchantGSTEnable: false,
    merchantInfo: {},
    AppTitle: "",
    currentCatelogueType: {},
    orderStatusList: [],
    showErrorMessageModal: false,
    errorMessage: "",
    showSuccessMessageModal: false,
    successMessage: "",
    masterDetailsObj: {},
    skip: 0,
    isFullScreenImage: false,
    fullScreenImagePath: "",
    updatedQty: "",
  };
  // constructor(props) {
  //     super(props)
  // }

  componentDidMount() {
    const userDetails = loadUserDetailsFromLocalStorage();
    if (isOpenStoreIdExist() || userDetails === undefined) {
      navigateTo(this.props, `/login`);
      return;
    }
    this.initMasterDetailsId().then(() => {
      const { masterDetailsObj } = this.state;
      this.initBarcodeSettings();
      this.initOrderStatus().then(() => {
        this.initSingleOrder().then(() => {
          this.setState({ isLoading: false });
        });
      });
      this.initCatelogueSettings();
      this.initMerchantInfo();
      this.initShippingCharges();
      this.initOrderStatus();
      this.props.isInquiryOrCart(masterDetailsObj);
      this.props.notificationCounter();
      this.props.counter();
    });
  }

  initMasterDetailsId = async () => {
    let masterDetailsObj = {};
    const publicUrl = window.location.pathname.split("/")[1];
    const masterDetailsIdUrl = getMasterDetailsIdUrl(publicUrl);
    debuglogger("masterDetailsIdUrl", masterDetailsIdUrl);
    const masterDetails = await getCall(masterDetailsIdUrl, []).then();
    if (masterDetails.error) {
      clearLocalStorageReload({ response: masterDetails });
    } else {
      if (masterDetails) {
        masterDetailsObj = masterDetails;
      }
    }
    this.setState({ masterDetailsObj: masterDetailsObj });
  };

  initBarcodeSettings = async () => {
    const { masterDetailsObj } = this.state;
    const barcodeDetails = await getCall(
      getBarcodeSetting(masterDetailsObj),
      []
    );
    if (barcodeDetails.error) {
      clearLocalStorageReload({ response: barcodeDetails });
      this.loadErrorMessageAnimation(barcodeDetails.error.message);
    } else {
      if (barcodeDetails.length) {
        this.setState({
          barcodeDetails: barcodeDetails[0],
          isBarcode: barcodeDetails[0].status == 1 ? true : false,
          barcodeError: false,
          barcodeErrorMessage: "",
        });
      }
    }
  };

  initSingleOrder = async () => {
    const { isInquiry } = this.props;
    const { orderId, orderStatusList } = this.state;
    const userDetails = loadUserDetailsFromLocalStorage();
    if (userDetails === undefined) {
      return;
    }
    const singleOrderUrl = `orders/${orderId}?filter[include]=orderdetails&filter[include]=productmedia`;
    debuglogger("singleOrderUrl", singleOrderUrl);
    const singleOrder = await getCall(singleOrderUrl, []);
    if (singleOrder.error) {
      clearLocalStorageReload({ response: singleOrder });
      if (singleOrder.error.statusCode === 401) {
        this.loadErrorMessageAnimation(singleOrder.error.message);
        removeUserDetailAndOtherDetails();
        navigateTo(this.props, `/login`);
        return;
      }
      if (singleOrder.error.statusCode === 500) {
        isInquiry
          ? this.loadErrorMessageAnimation(
              getValueFromLanguageObject("str_oops_inquiry_is_not_available")
                ? getValueFromLanguageObject(
                    "str_oops_inquiry_is_not_available"
                  )
                : "Opps! inquiry is not available"
            )
          : this.loadErrorMessageAnimation(
              getValueFromLanguageObject("str_oops_order_is_not_available")
                ? getValueFromLanguageObject("str_oops_order_is_not_available")
                : "Opps! order is not available"
            );

        this.props.history.push("/orders");
        return;
      }
      this.loadErrorMessageAnimation(singleOrder.error.message);
    } else {
      this.setState({ singleOrder });
      if (singleOrder.orderdetail) {
        this.setState({ orderDetails: singleOrder.orderdetail });
      }

      if (orderStatusList && orderStatusList.length > 0) {
        let isFound = false;
        orderStatusList.forEach((orderStatusItem) => {
          if (
            singleOrder.orderstatus === orderStatusItem.id &&
            orderStatusItem.status !== "PENDING"
          ) {
            isFound = true;
            return;
          }
        });

        if (isFound) {
          isInquiry
            ? this.loadErrorMessageAnimation(
                getValueFromLanguageObject(
                  "str_you_are_not_allowed_to_edit_inquiry"
                )
                  ? getValueFromLanguageObject(
                      "str_you_are_not_allowed_to_edit_inquiry"
                    )
                  : "you are not allowed to edit inquiry"
              )
            : this.loadErrorMessageAnimation(
                getValueFromLanguageObject(
                  "str_you_are_not_allowed_to_edit_order"
                )
                  ? getValueFromLanguageObject(
                      "str_you_are_not_allowed_to_edit_order"
                    )
                  : "you are not allowed to edit order"
              );

          setTimeout(() => {
            this.props.history.push("/orders");
          }, 500);
        }
      }
    }
  };

  initCatelogueSettings = async () => {
    const { masterDetailsObj } = this.state;
    const settingUrl = getSettingUrl(masterDetailsObj);
    debuglogger("settingUrl", settingUrl);
    const settingInfo = await getCall(settingUrl, []);
    if (settingInfo.error) {
      clearLocalStorageReload({ response: settingInfo });
    } else {
      let settings = {};
      settingInfo.forEach((setting) => {
        if (
          (setting.registerallow === "Catalogue_FMCG" ||
            setting.registerallow === "Catalogue_Jewellary" ||
            setting.registerallow === "Catalogue_Electronics" ||
            setting.registerallow === "Catalogue_FABRIC") &&
          setting.status === 1
        ) {
          settings = setting;
        }
      });
      this.setState({ currentCatelogueType: settings });
    }
  };

  initShippingCharges = async () => {
    const { masterDetailsObj } = this.state;
    const shippingChargesUrl = getShippingOptionsUrl(masterDetailsObj);
    debuglogger("shippingChargesUrl", shippingChargesUrl);
    const shippingCharges = await getCall(shippingChargesUrl, []);
    if (shippingCharges.error) {
      clearLocalStorageReload({ response: shippingCharges });
    }
    this.setState({ shippingCharges: shippingCharges });
  };

  initOrderStatus = async () => {
    const { masterDetailsObj } = this.state;
    const orderStatusUrl = getOrderStatusUrl(masterDetailsObj);
    debuglogger("orderStatusUrl", orderStatusUrl);
    const orderStatusList = await getCall(orderStatusUrl, []);
    if (orderStatusList.error) {
      clearLocalStorageReload({ response: orderStatusList });
      this.loadErrorMessageAnimation(orderStatusList.error.message);
      if (orderStatusList.error.statusCode === 401) {
        removeUserDetailAndOtherDetails();
        navigateTo(this.props, `/login`);
        return;
      }
    } else {
      this.setState({ orderStatusList });
    }
  };

  minusQty = (orderDetail, index) => {
    this.setState({ isLoading: true }, () => {
      this.editOrderProduct(orderDetail, index, 0).then(() => {
        this.setState({ isLoading: false });
      });
    });
  };

  plusQty = (orderDetail, index) => {
    this.setState({ isLoading: true }, () => {
      this.editOrderProduct(orderDetail, index, 1).then(() => {
        this.setState({ isLoading: false });
      });
    });
  };

  checkSetProduct = (orderDetail) => {
    const { products } = orderDetail;
    if (products) {
      let setData = JSON.parse(products.productSetJson);
      return setData && setData.isSetProduct ? setData.setQuantity : 1;
    }
  };

  editOrderProduct = async (orderDetail, index, operation) => {
    const {
      orderDetails,
      merchantInfo,
      isMerchantGSTEnable,
      currentCatelogueType,
    } = this.state;
    // operation == 0 --- Decrese Qty
    // operation == 1 --- Increase Qty
    const { singleOrder } = this.state;
    const { isInquiry } = this.props;

    const userDetails = loadUserDetailsFromLocalStorage();
    if (userDetails === undefined) {
      return;
    }
    const { id } = userDetails;
    const total = this.getTotalofCart(singleOrder);
    const qty = this.checkSetProduct(orderDetail);
    const tempQty =
      operation === 0 ? orderDetail.quantity - qty : orderDetail.quantity + qty;

    if (orderDetail.products.minimumorderquantity > tempQty) {
      return;
    }
    if (tempQty === 0) {
      return;
    }
    const tempAmount = orderDetail.amount;
    const orderId = singleOrder.id;
    const additionalServiceChargesAmount = this.getServiceChargesBasedOnTotalAmount();
    const editCartUrl = "orders/" + orderId;
    var editCartObj = {};
    const subTotal = this.getSubTotalFromTotalandServiceCharges();
    if (
      isMerchantGSTEnable &&
      currentCatelogueType &&
      currentCatelogueType.registerallow !== "Catalogue_Jewellary"
    ) {
      editCartObj = {
        additionalcharge: additionalServiceChargesAmount,
        totalamount:
          operation === 0
            ? this.getGrandTotal(
                subTotal - tempAmount,
                this.getCGSTFromTotal(subTotal - tempAmount),
                this.getSGSTFromTotal(subTotal - tempAmount),
                this.getIGSTFromTotal(subTotal - tempAmount),
                this.getShippingCharges(subTotal - tempAmount)
              )
            : this.getGrandTotal(
                subTotal + tempAmount,
                this.getCGSTFromTotal(subTotal + tempAmount),
                this.getSGSTFromTotal(subTotal + tempAmount),
                this.getIGSTFromTotal(subTotal + tempAmount),
                this.getShippingCharges(subTotal + tempAmount)
              ),
        userId: id,
        inshoppingcart: isInquiry ? 2 : 0,
        orderdetails: [
          {
            id: orderDetail.id,
            quantity: tempQty,
          },
        ],
        tax: {
          cgst: merchantInfo.CGST,
          sgst: merchantInfo.SGST,
          cgstPrice:
            operation === 0
              ? this.getCGSTFromTotal(subTotal - tempAmount)
              : this.getCGSTFromTotal(subTotal + tempAmount),
          sgstPrice:
            operation === 0
              ? this.getSGSTFromTotal(subTotal - tempAmount)
              : this.getSGSTFromTotal(subTotal + tempAmount),
        },
        baseprice:
          operation === 0 ? subTotal - tempAmount : subTotal + tempAmount,
        shippingprice:
          operation === 0
            ? this.getShippingCharges(subTotal - tempAmount)
            : this.getShippingCharges(subTotal + tempAmount),
      };
    } else if (
      isMerchantGSTEnable &&
      currentCatelogueType &&
      currentCatelogueType.registerallow === "Catalogue_Jewellary"
    ) {
      editCartObj = {
        additionalcharge: additionalServiceChargesAmount,
        totalamount:
          operation === 0
            ? this.getGrandTotal(
                subTotal - tempAmount,
                this.getCGSTFromTotal(subTotal - tempAmount),
                this.getSGSTFromTotal(subTotal - tempAmount),
                this.getIGSTFromTotal(subTotal - tempAmount),
                this.getShippingCharges(subTotal - tempAmount)
              )
            : this.getGrandTotal(
                subTotal + tempAmount,
                this.getCGSTFromTotal(subTotal + tempAmount),
                this.getSGSTFromTotal(subTotal + tempAmount),
                this.getIGSTFromTotal(subTotal + tempAmount),
                this.getShippingCharges(subTotal + tempAmount)
              ),
        userId: id,
        inshoppingcart: isInquiry ? 2 : 0,
        orderdetails: [
          {
            id: orderDetail.id,
            quantity: tempQty,
          },
        ],
        baseprice:
          operation === 0 ? subTotal - tempAmount : subTotal + tempAmount,
        shippingprice:
          operation === 0
            ? this.getShippingCharges(subTotal - tempAmount)
            : this.getShippingCharges(subTotal + tempAmount),
      };
    } else {
      editCartObj = {
        additionalcharge: additionalServiceChargesAmount,
        totalamount:
          operation === 0
            ? total -
              tempAmount -
              this.getShippingCharges(subTotal - tempAmount)
            : total +
              tempAmount +
              this.getShippingCharges(subTotal + tempAmount),
        userId: id,
        inshoppingcart: isInquiry ? 2 : 0,
        orderdetails: [
          {
            id: orderDetail.id,
            quantity: tempQty,
          },
        ],
        shippingprice:
          operation === 0
            ? this.getShippingCharges(subTotal - tempAmount)
            : this.getShippingCharges(subTotal + tempAmount),
      };
    }

    const editOrder = await patchCall(editCartUrl, editCartObj);
    if (editOrder.error) {
      clearLocalStorageReload({ response: editOrder });
      this.loadErrorMessageAnimation(editOrder.error.message);
      if (editOrder.error.statusCode === 401) {
        removeUserDetailAndOtherDetails();
        navigateTo(this.props, `/login`);
        return;
      }
    } else {
      if (isInquiry) {
        this.loadSuccessMessageAnimation(
          getValueFromLanguageObject("msg_your_inquiry_updated_successfully")
            ? getValueFromLanguageObject(
                "msg_your_inquiry_updated_successfully"
              )
            : "Your inquiry updated successfully"
        );
      } else {
        this.loadSuccessMessageAnimation(
          getValueFromLanguageObject("msg_your_order_updated_successfully")
            ? getValueFromLanguageObject("msg_your_order_updated_successfully")
            : "Your order updated successfully."
        );
      }

      const tempOrderDetail = orderDetails;
      tempOrderDetail[index].quantity = tempQty;
      this.setState({ orderDetails: tempOrderDetail });
      this.props.notificationCounter();
    }
  };

  changeQty = (index, e) => {
    const { orderDetails, singleOrder } = this.state;
    let qty = e.target.value;
    if (qty !== "" && isFinite(qty)) {
      this.setState({ updatedQty: qty });
      const tempOrderDetail = orderDetails;
      tempOrderDetail[index].quantity = parseInt(qty);
      const tempSingleOrder = singleOrder;
      tempSingleOrder.orderdetail = tempOrderDetail;
      this.setState({
        orderDetails: tempOrderDetail,
        singleOrder: tempSingleOrder,
      });
    } else {
      const { orderDetails } = this.state;
      const tempOrderDetail = orderDetails;
      tempOrderDetail[index].quantity = "";
      const tempSingleOrder = singleOrder;
      tempSingleOrder.orderdetail = tempOrderDetail;
      this.setState({
        orderDetails: tempOrderDetail,
        singleOrder: tempSingleOrder,
      });
    }
  };

  updateEditOrderAPI = async (orderDetail) => {
    try {
      const {
        singleOrder,
        isMerchantGSTEnable,
        merchantInfo,
        currentCatelogueType,
        updatedQty,
      } = this.state;
      const { isInquiry } = this.props;
      const userDetails = loadUserDetailsFromLocalStorage();
      if (userDetails === undefined) {
        return;
      }
      const { id: userId } = userDetails;
      const { id: orderId } = singleOrder || {};
      const tempQty = updatedQty;
      if (orderDetail.products.minimumorderquantity > tempQty) {
        return;
      }
      if (tempQty === 0) {
        return;
      }
      const editCartUrl = "orders/" + orderId;
      const subTotal = this.getSubTotalFromTotalandServiceCharges();
      const totalAmount = this.getGrandTotal(
        subTotal,
        this.getCGSTFromTotal(subTotal),
        this.getSGSTFromTotal(subTotal),
        this.getIGSTFromTotal(subTotal),
        this.getShippingCharges(subTotal)
      );

      const shippingPrice = this.getShippingCharges(subTotal);
      const additionalServiceChargesAmount = this.getServiceChargesBasedOnTotalAmount();
      let editCartObj = {
        additionalcharge: additionalServiceChargesAmount,
        totalamount: totalAmount,
        userId: userId,
        inshoppingcart: isInquiry ? 2 : 0,
        orderdetails: [
          {
            id: orderDetail.id,
            quantity: tempQty,
          },
        ],
        baseprice: subTotal,
        shippingprice: shippingPrice,
        ...(isMerchantGSTEnable &&
          currentCatelogueType &&
          currentCatelogueType.registerallow !== "Catalogue_Jewellary" && {
            tax: {
              cgst: merchantInfo.CGST,
              sgst: merchantInfo.SGST,
              cgstPrice: this.getCGSTFromTotal(subTotal),
              sgstPrice: this.getSGSTFromTotal(subTotal),
            },
          }),
      };
      this.setState({ isLoading: true });
      const editOrder = await patchCall(editCartUrl, editCartObj);
      this.setState({ isLoading: false });
      if (editOrder.error) {
        clearLocalStorageReload({ response: editOrder });
        this.loadErrorMessageAnimation(editOrder.error.message);
        if (editOrder.error.statusCode === 401) {
          removeUserDetailAndOtherDetails();
          navigateTo(this.props, `/login`);
          return;
        }
        this.initSingleOrder();
      } else {
        const strYourInquiryUpdatedSuccessfully = getValueFromLanguageObject(
          "msg_your_inquiry_updated_successfully"
        )
          ? getValueFromLanguageObject("msg_your_inquiry_updated_successfully")
          : "Your inquiry updated successfully";

        const strYourOrderUpdatedSuccessfully = getValueFromLanguageObject(
          "msg_your_order_updated_successfully"
        )
          ? getValueFromLanguageObject("msg_your_order_updated_successfully")
          : "Your order updated successfully";

        if (isInquiry) {
          this.loadSuccessMessageAnimation(strYourInquiryUpdatedSuccessfully);
        } else {
          this.loadSuccessMessageAnimation(strYourOrderUpdatedSuccessfully);
        }
      }
    } catch {
      this.setState({ isLoading: false });
    }
  };

  getTotalofCart(singleOrder) {
    let total = 0;
    if (singleOrder && singleOrder.orderdetail) {
      for (let i = 0; i < singleOrder.orderdetail.length; i++) {
        total =
          total +
          singleOrder.orderdetail[i].amount *
            singleOrder.orderdetail[i].quantity;
      }
    }

    return total;
  }

  getSubTotalFromTotalandServiceCharges = () => {
    const { singleOrder } = this.state || {};
    let subTotal = 0;
    const total = this.getTotalofCart(singleOrder);
    const serviceChargeAmount = parseFloat(
      this.getServiceChargesBasedOnTotalAmount()
    );
    subTotal = total + serviceChargeAmount;
    return subTotal;
  };

  initMerchantInfo = async () => {
    const { masterDetailsObj } = this.state;
    const merchantInfoUrl = getMerchantInformationUrl(masterDetailsObj);
    debuglogger("merchantInfoUrl", merchantInfoUrl);
    const merchantInfo = await getCall(merchantInfoUrl, []);
    if (merchantInfo.error) {
      clearLocalStorageReload({ response: merchantInfo });
      this.setState({
        isMerchantGSTEnable: false,
        merchantInfo: {},
        AppTitle: "",
      });
    } else {
      this.setState({
        merchantInfo,
        AppTitle: merchantInfo.companyname,
        isMerchantGSTEnable: merchantInfo.enablegst === 0 ? false : true,
      });
    }
  };

  getCGSTFromTotal = (total) => {
    const { merchantInfo } = this.state;
    return (total * merchantInfo.CGST) / 100;
  };
  getSGSTFromTotal = (total) => {
    const { merchantInfo } = this.state;
    return (total * merchantInfo.SGST) / 100;
  };

  getIGSTFromTotal = (total) => {
    const { merchantInfo } = this.state;
    return (total * merchantInfo.IGST) / 100;
  };

  getGrandTotal = (total, cgst, sgst, igst, shippingCharge) => {
    const { merchantInfo, currentCatelogueType } = this.state;
    var grandTotal = 0;
    if (merchantInfo.enablegst === 0) {
      grandTotal = total + shippingCharge;
      return grandTotal;
    }
    if (
      merchantInfo.enablegst === 1 &&
      currentCatelogueType &&
      currentCatelogueType.registerallow === "Catalogue_Jewellary"
    ) {
      grandTotal = total + shippingCharge;
      return grandTotal;
    }
    if (merchantInfo.countryname === "India") {
      grandTotal = total + cgst + sgst + shippingCharge;
    } else {
      grandTotal = total + igst + shippingCharge;
    }
    return grandTotal;
  };

  getShippingCharges = (total) => {
    const { shippingCharges } = this.state;
    var charge = 0;
    if (shippingCharges.length > 0) {
      for (let index = 0; index < shippingCharges.length; index++) {
        if (
          shippingCharges[index].id === 3 &&
          shippingCharges[index].status === 1
        ) {
          for (
            let jindex = 0;
            jindex < shippingCharges[index].options.length;
            jindex++
          ) {
            if (
              !shippingCharges[index].options[jindex].maxCondition &&
              shippingCharges[index].options[jindex].minValue <= total &&
              shippingCharges[index].options[jindex].maxValue >= total
            ) {
              charge = shippingCharges[index].options[jindex].charges;
              break;
            }
            if (
              shippingCharges[index].options[jindex].maxCondition &&
              shippingCharges[index].options[jindex].minValue <= total
            ) {
              charge = shippingCharges[index].options[jindex].charges;
              break;
            }
          }
        }
      }
    }
    return charge;
  };

  onClickOfDeleteIcon = (item, index) => {
    const { orderDetails } = this.state;
    confirmAlert({
      customUI: ({ onClose }) => {
        const submitClick = (props) => {
          this.setState({ isLoading: true }, () => {
            if (orderDetails && orderDetails.length === 1) {
              this.cancelOrder(item, index).then(() => {
                this.setState({ isLoading: false });
              });
            } else {
              this.removeProductFromOrder(item, index).then(() => {
                this.setState({ isLoading: false });
              });
            }
            onClose();
          });
        };
        return (
          <>
            <ConfirmAlertPopup
              title={
                getValueFromLanguageObject("str_title_remove_product")
                  ? getValueFromLanguageObject("str_title_remove_product")
                  : "Remove Product"
              }
              message={
                getValueFromLanguageObject("str_remove_product_cart")
                  ? getValueFromLanguageObject("str_remove_product_cart")
                  : "Are you sure you want to remove this product?"
              }
              messageDescription={
                orderDetails && orderDetails.length === 1
                  ? getValueFromLanguageObject(
                      "str_note_if_you_remove_this_item_your_order_will_be_cancelled"
                    )
                    ? getValueFromLanguageObject(
                        "str_note_if_you_remove_this_item_your_order_will_be_cancelled"
                      )
                    : "Note : If you will remove this item your order will be cancelled!"
                  : ""
              }
              animation={<DeleteAnim />}
              submit={
                getValueFromLanguageObject("str_yes_remove_it")
                  ? getValueFromLanguageObject("str_yes_remove_it")
                  : "Yes, Remove it!"
              }
              submitClick={submitClick}
              cancelClick={onClose}
              cancel={
                getValueFromLanguageObject("str_no")
                  ? getValueFromLanguageObject("str_no")
                  : "No"
              }
            />
          </>
        );
      },
    });
  };

  cancelOrder = async (order, index) => {
    const { isInquiry } = this.props;
    const { singleOrder } = this.state;

    const cancelOrderUrl = `orders/cancelOrder`;
    const cancelOrderObj = {
      orderstatus: 5,
      orderId: singleOrder.id,
    };

    const cancelOrder = await patchCall(cancelOrderUrl, cancelOrderObj);
    if (cancelOrder.error) {
      clearLocalStorageReload({ response: cancelOrder });
      this.loadErrorMessageAnimation(cancelOrder.error.message);
      if (cancelOrder.error.statusCode === 401) {
        removeUserDetailAndOtherDetails();
        return;
      }
    } else {
      if (isInquiry) {
        this.loadSuccessMessageAnimation(
          getValueFromLanguageObject("msg_your_inquiry_is_successfully_cancel")
            ? getValueFromLanguageObject(
                "msg_your_inquiry_is_successfully_cancel"
              )
            : "Your inquiry is successfully cancelled"
        );
      } else {
        this.loadSuccessMessageAnimation(
          getValueFromLanguageObject("msg_your_order_is_successfully_cancel")
            ? getValueFromLanguageObject(
                "msg_your_order_is_successfully_cancel"
              )
            : "Your order is successfully cancelled"
        );
      }
      setTimeout(() => {
        this.props.history.push("/orders");
      }, 1000);
    }
  };

  removeProductFromOrder = async (orderDetail, index) => {
    const {
      orderDetails,
      isMerchantGSTEnable,
      merchantInfo,
      currentCatelogueType,
    } = this.state;
    const { isInquiry } = this.props;
    const { singleOrder } = this.state;
    const userDetails = loadUserDetailsFromLocalStorage();
    if (userDetails === undefined) {
      return;
    }
    const { id } = userDetails;
    const total = this.getTotalofCart(singleOrder);
    const tempAmount = orderDetail.amount * orderDetail.quantity;
    const orderId = singleOrder.id;
    const additionalServiceChargesAmount = this.getServiceChargesBasedOnTotalAmount();
    const removeProductFromOrderUrl = "orders/" + orderId;
    var removeProductFromOrderObj = {};
    const subTotal = this.getSubTotalFromTotalandServiceCharges();
    if (
      isMerchantGSTEnable &&
      currentCatelogueType &&
      currentCatelogueType.registerallow !== "Catalogue_Jewellary"
    ) {
      removeProductFromOrderObj = {
        additionalcharge: additionalServiceChargesAmount,
        totalamount: this.getGrandTotal(
          subTotal - tempAmount,
          this.getCGSTFromTotal(subTotal - tempAmount),
          this.getSGSTFromTotal(subTotal - tempAmount),
          this.getIGSTFromTotal(subTotal - tempAmount),
          this.getShippingCharges(subTotal - tempAmount)
        ),

        userId: id,
        inshoppingcart: isInquiry ? 2 : 0,
        orderdetails: [
          {
            id: orderDetail.id,
            deletedAt: new Date(),
          },
        ],
        tax: {
          cgst: merchantInfo.CGST,
          sgst: merchantInfo.SGST,
          cgstPrice: this.getCGSTFromTotal(subTotal - tempAmount),

          sgstPrice: this.getSGSTFromTotal(subTotal - tempAmount),
        },
        baseprice: subTotal - tempAmount,
        shippingprice: this.getShippingCharges(subTotal - tempAmount),
      };
    } else if (
      isMerchantGSTEnable &&
      currentCatelogueType &&
      currentCatelogueType.registerallow === "Catalogue_Jewellary"
    ) {
      removeProductFromOrderObj = {
        additionalcharge: additionalServiceChargesAmount,
        totalamount: this.getGrandTotal(
          subTotal - tempAmount,
          this.getCGSTFromTotal(subTotal - tempAmount),
          this.getSGSTFromTotal(subTotal - tempAmount),
          this.getIGSTFromTotal(subTotal - tempAmount),
          this.getShippingCharges(subTotal - tempAmount)
        ),
        userId: id,
        inshoppingcart: isInquiry ? 2 : 0,
        orderdetails: [
          {
            id: orderDetail.id,
            deletedAt: new Date(),
          },
        ],
        baseprice: subTotal - tempAmount,
        shippingprice: this.getShippingCharges(subTotal - tempAmount),
      };
    } else {
      removeProductFromOrderObj = {
        additionalcharge: additionalServiceChargesAmount,
        totalamount:
          total - tempAmount - this.getShippingCharges(subTotal - tempAmount),
        userId: id,
        inshoppingcart: isInquiry ? 2 : 0,
        orderdetails: [
          {
            id: orderDetail.id,
            deletedAt: new Date(),
          },
        ],
        shippingprice: this.getShippingCharges(subTotal - tempAmount),
      };
    }

    const removeProduct = await patchCall(
      removeProductFromOrderUrl,
      removeProductFromOrderObj
    );
    if (removeProduct.error) {
      clearLocalStorageReload({ response: removeProduct });
      this.loadErrorMessageAnimation(removeProduct.error.message);
      if (singleOrder.error.statusCode === 401) {
        removeUserDetailAndOtherDetails();
        navigateTo(this.props, `/login`);
        return;
      }
    } else {
      if (isInquiry) {
        this.loadSuccessMessageAnimation(
          getValueFromLanguageObject("msg_your_inquiry_updated_successfully")
            ? getValueFromLanguageObject(
                "msg_your_inquiry_updated_successfully"
              )
            : "Your inquiry updated successfully"
        );
      } else {
        this.loadSuccessMessageAnimation(
          getValueFromLanguageObject("msg_your_order_updated_successfully")
            ? getValueFromLanguageObject("msg_your_order_updated_successfully")
            : "Your order updated successfully"
        );
      }

      if (orderDetails && orderDetails.length === 1) {
        this.setState({ orderDetails: [] });
        return;
      }
      this.setState({ isLoading: true }, () => {
        this.initSingleOrder().then(() => {
          this.setState({ isLoading: false });
        });
      });

      this.props.notificationCounter();
    }
  };

  loadErrorMessageAnimation = (message) => {
    this.setState({ errorMessage: message, showErrorMessageModal: true });
  };

  loadSuccessMessageAnimation = (message) => {
    this.setState({ successMessage: message, showSuccessMessageModal: true });
  };

  renderServiceChargesLabel = () => {
    let labelServiceCharges = "";
    const { singleOrder } = this.state || {};
    const { additionalchargedetails } = singleOrder || {};
    if (additionalchargedetails) {
      const additionalchargedetailsObj =
        JSON.parse(additionalchargedetails) || {};
      const { label = "" } = additionalchargedetailsObj || {};
      labelServiceCharges = label;
    }
    return labelServiceCharges;
  };

  getServiceChargesBasedOnTotalAmount = () => {
    let serviceChargesAmount = 0;
    const { singleOrder } = this.state || {};
    const { additionalchargedetails } = singleOrder || {};
    if (additionalchargedetails) {
      const additionalchargedetailsObj =
        JSON.parse(additionalchargedetails) || {};
      const { type = "", typevalue } = additionalchargedetailsObj || {}; // type = isPercentage, typevalue = amount
      if (type === "isPercentage") {
        const totalAmount = this.getTotalofCart(singleOrder);
        serviceChargesAmount = (totalAmount * typevalue) / 100;
      } else {
        serviceChargesAmount = parseFloat(typevalue);
      }
    }
    return serviceChargesAmount;
  };

  render() {
    const { symbol } = this.props;
    const { isInquiry } = this.props;

    const {
      orderDetails,
      singleOrder,
      isLoading,
      isMerchantGSTEnable,
      AppTitle,
      currentCatelogueType,
      showErrorMessageModal,
      errorMessage,
      showSuccessMessageModal,
      successMessage,
      masterDetailsObj,
      isFullScreenImage,
      fullScreenImagePath,
    } = this.state;

    const subTotal = this.getSubTotalFromTotalandServiceCharges();

    return (
      <>
        <div>
          {/*SEO Support*/}
          <Helmet>
            <title>
              {AppTitle} |{" "}
              {isInquiry
                ? getValueFromLanguageObject("str_edit_inquiry_page")
                  ? getValueFromLanguageObject("str_edit_inquiry_page")
                  : "Edit Inquiry Page"
                : getValueFromLanguageObject("str_edit_order_page")
                ? getValueFromLanguageObject("str_edit_order_page")
                : "Edit Order Page"}
            </title>
            <meta
              name="description"
              content="Multikart – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Multikart Bootstrap 4 Template will help you run multiple businesses."
            />
          </Helmet>
          {/*SEO Support End */}
          {isInquiry ? (
            <Breadcrumb
              title={
                getValueFromLanguageObject("str_edit_inquiry")
                  ? getValueFromLanguageObject("str_edit_inquiry")
                  : "Edit Inquiry"
              }
            />
          ) : (
            <Breadcrumb
              title={
                getValueFromLanguageObject("str_edit_order")
                  ? getValueFromLanguageObject("str_edit_order")
                  : "Edit Order"
              }
              orderId={this.state.orderId}
            />
          )}

          {orderDetails && orderDetails.length > 0 ? (
            <section className="cart-section section-b-space custom-cart">
              <div className="container">
                <div className="row">
                  <div className="col-sm-12">
                    <table className="table cart-table table-responsive-xs">
                      <thead>
                        <tr className="table-head">
                          <th scope="col">
                            {getValueFromLanguageObject("str_image")
                              ? getValueFromLanguageObject("str_image")
                              : "image"}
                          </th>
                          <th scope="col">
                            {getValueFromLanguageObject("str_product_name")
                              ? getValueFromLanguageObject("str_product_name")
                              : "product name"}
                          </th>
                          {!isInquiry && (
                            <th scope="col">
                              {getValueFromLanguageObject("str_price")
                                ? getValueFromLanguageObject("str_price")
                                : "price"}
                            </th>
                          )}

                          <th scope="col">
                            {getValueFromLanguageObject("str_quantity")
                              ? getValueFromLanguageObject("str_quantity")
                              : "quantity"}
                          </th>
                          <th scope="col">
                            {getValueFromLanguageObject("str_action")
                              ? getValueFromLanguageObject("str_action")
                              : "action"}
                          </th>
                          {!isInquiry && (
                            <th scope="col">
                              {getValueFromLanguageObject("str_total")
                                ? getValueFromLanguageObject("str_total")
                                : "total"}
                            </th>
                          )}
                        </tr>
                      </thead>
                      {orderDetails.map((item, index) => {
                        return (
                          <tbody key={index}>
                            <tr>
                              <td
                                onClick={() => {
                                  const path =
                                    item.products.productmedia.length > 0
                                      ? getImageUrl(
                                          item.products.productmedia[0]
                                            .productname,
                                          masterDetailsObj
                                        )
                                      : getPlaceHolderImage();
                                  this.setState({
                                    isFullScreenImage: true,
                                    fullScreenImagePath: path,
                                  });
                                }}
                              >
                                {/* <Link to={`/singleproduct/${item.products.id}`}> */}
                                <img
                                  className="view-image"
                                  src={
                                    item.products.productmedia.length > 0
                                      ? getImageUrl(
                                          item.products.productmedia[0]
                                            .productname,
                                          masterDetailsObj
                                        )
                                      : getPlaceHolderImage()
                                  }
                                  alt=""
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = getPlaceHolderImage();
                                  }}
                                />
                                {/* </Link> */}
                              </td>
                              <td>
                                <Link to={`/singleproduct/${item.products.id}`}>
                                  {item.products.name}
                                </Link>
                                {/* <div className="mobile-cart-content row">
                                  <div className="col-xs-3">
                                    <div className="qty-box">
                                      <div className="input-group">
                                        <input
                                          type="number"
                                          name="quantity"
                                          onChange={(e) => {
                                            this.changeQty(item, index, e);
                                          }}
                                          value={item.quantity}
                                          className="form-control input-number"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  {!isInquiry && (
                                    <div className="col-xs-3">
                                      <b
                                        className="td-color"
                                        style={{
                                          color: getColorCodeBasedOnKey(
                                            "text_price_edit_order"
                                          ),
                                        }}
                                      >
                                        {symbol}
                                        {parseFloat(item.amount).toFixed(2)}
                                      </b>
                                    </div>
                                  )}

                                  <div className="col-xs-3">
                                    <b className="td-color">
                                      <div
                                        className="icon"
                                        onClick={() => {
                                          this.onClickOfDeleteIcon(item, index);
                                        }}
                                      >
                                        <i className="fa fa-trash" />
                                      </div>
                                    </b>
                                  </div>
                                </div> */}
                              </td>
                              {!isInquiry && (
                                <td>
                                  <b>
                                    {symbol}
                                    {parseFloat(item.amount).toFixed(2)}
                                  </b>
                                </td>
                              )}

                              <td>
                                <div className="qty-box">
                                  <div className="input-group">
                                    <span className="input-group-prepend">
                                      <button
                                        type="button"
                                        className="btn quantity-left-minus"
                                        onClick={() =>
                                          this.minusQty(item, index)
                                        }
                                        data-type="minus"
                                        data-field=""
                                      >
                                        <i className="fa fa-angle-left" />
                                      </button>
                                    </span>
                                    <input
                                      type="number"
                                      name="quantity"
                                      value={item.quantity}
                                      onChange={(e) => this.changeQty(index, e)}
                                      onBlur={(e) => {
                                        this.updateEditOrderAPI(item);
                                      }}
                                      className="form-control input-number"
                                      min="0"
                                      max="100000"
                                    />
                                    <span className="input-group-prepend">
                                      <button
                                        className="btn quantity-right-plus"
                                        onClick={() =>
                                          this.plusQty(item, index)
                                        }
                                        data-type="plus"
                                      >
                                        <i className="fa fa-angle-right" />
                                      </button>
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div
                                  className="icon"
                                  onClick={() => {
                                    this.onClickOfDeleteIcon(item, index);
                                  }}
                                >
                                  <i className="fa fa-trash" />
                                </div>
                              </td>

                              {!isInquiry && (
                                <td>
                                  <b className="td-color">
                                    {symbol}
                                    {item.quantity *
                                      parseFloat(item.amount).toFixed(2)}
                                  </b>
                                </td>
                              )}
                            </tr>
                          </tbody>
                        );
                      })}
                    </table>
                    <div className="row">
                      <div class="col-md-6" />
                      <div className="col-md-6">
                        {!isInquiry && (
                          <div className="cart-total">
                            <table className="table cart-table table-responsive-md">
                              <tfoot className="default-padding__cart__edit__order">
                                <tr>
                                  <td>
                                    {getValueFromLanguageObject(
                                      "str_total_price_with_colun"
                                    )
                                      ? getValueFromLanguageObject(
                                          "str_total_price_with_colun"
                                        )
                                      : "total price :"}
                                  </td>
                                  <td>
                                    <b>
                                      {symbol}
                                      {parseFloat(
                                        this.getTotalofCart(singleOrder)
                                      ).toFixed(2)}
                                    </b>
                                  </td>
                                </tr>

                                <tr>
                                  <td>
                                    {`+ ${this.renderServiceChargesLabel()}`}
                                  </td>

                                  <td>
                                    <b>
                                      {symbol}
                                      {this.getServiceChargesBasedOnTotalAmount()}
                                    </b>
                                  </td>
                                </tr>

                                <tr>
                                  <td>
                                    {"+ "}
                                    {getValueFromLanguageObject(
                                      "shipping_charges_with_colun"
                                    )
                                      ? getValueFromLanguageObject(
                                          "shipping_charges_with_colun"
                                        )
                                      : "Shipping Charges : "}
                                  </td>
                                  <td>
                                    <b>
                                      {symbol}
                                      {parseFloat(
                                        this.getShippingCharges(subTotal)
                                      ).toFixed(2)}
                                    </b>
                                  </td>
                                </tr>

                                {isMerchantGSTEnable &&
                                  currentCatelogueType &&
                                  currentCatelogueType.registerallow !==
                                    "Catalogue_Jewellary" &&
                                  this.state.merchantInfo.countryname ===
                                    "India" && (
                                    <tr>
                                      <td>
                                        {"+ "}
                                        {getValueFromLanguageObject("str_cgst")
                                          ? getValueFromLanguageObject(
                                              "str_cgst"
                                            )
                                          : "CGST "}
                                        {"("}
                                        {this.state.merchantInfo.CGST}
                                        {"%) :"}
                                      </td>
                                      <td>
                                        <b>
                                          {symbol}
                                          {parseFloat(
                                            this.getCGSTFromTotal(subTotal)
                                          ).toFixed(2)}
                                        </b>
                                      </td>
                                    </tr>
                                  )}

                                {isMerchantGSTEnable &&
                                  currentCatelogueType &&
                                  currentCatelogueType.registerallow !==
                                    "Catalogue_Jewellary" &&
                                  this.state.merchantInfo.countryname ===
                                    "India" && (
                                    <tr>
                                      <td>
                                        {"+ "}
                                        {getValueFromLanguageObject("str_sgst")
                                          ? getValueFromLanguageObject(
                                              "str_sgst"
                                            )
                                          : "SGST "}{" "}
                                        {"("}
                                        {this.state.merchantInfo.SGST}
                                        {"%) : "}
                                      </td>
                                      <td>
                                        <b>
                                          {symbol}
                                          {parseFloat(
                                            this.getSGSTFromTotal(subTotal)
                                          ).toFixed(2)}
                                        </b>
                                      </td>
                                    </tr>
                                  )}

                                {isMerchantGSTEnable &&
                                  currentCatelogueType &&
                                  currentCatelogueType.registerallow !==
                                    "Catalogue_Jewellary" &&
                                  this.state.merchantInfo.countryname !==
                                    "India" && (
                                    <tr>
                                      <td
                                        style={{
                                          color: getColorCodeBasedOnKey(
                                            "text_igst_label_cart"
                                          ),
                                        }}
                                      >
                                        <b>
                                          {symbol}
                                          {parseFloat(
                                            this.getIGSTFromTotal(subTotal)
                                          ).toFixed(2)}
                                        </b>
                                      </td>
                                    </tr>
                                  )}

                                <tr>
                                  <td>
                                    {"- "}
                                    {getValueFromLanguageObject(
                                      "discount_with_colun"
                                    )
                                      ? getValueFromLanguageObject(
                                          "discount_with_colun"
                                        )
                                      : "Discount :"}
                                  </td>

                                  <td>
                                    <b>
                                      {symbol}
                                      {parseFloat(0).toFixed(2)}
                                    </b>
                                  </td>
                                </tr>

                                <tr className="order_total">
                                  <td>
                                    {getValueFromLanguageObject(
                                      "str_amount_payable_with_colun"
                                    )
                                      ? getValueFromLanguageObject(
                                          "str_amount_payable_with_colun"
                                        )
                                      : "Amount Payable : "}
                                  </td>
                                  <td>
                                    <b
                                      style={{
                                        color: getColorCodeBasedOnKey(
                                          "text_amount_payable_cart"
                                        ),
                                      }}
                                    >
                                      {symbol}
                                      {parseFloat(
                                        this.getGrandTotal(
                                          subTotal,
                                          this.getCGSTFromTotal(subTotal),
                                          this.getSGSTFromTotal(subTotal),
                                          this.getIGSTFromTotal(subTotal),
                                          this.getShippingCharges(subTotal)
                                        )
                                      ).toFixed(2)}
                                    </b>
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          ) : (
            <NoProductFoundEditOrderAnim />
          )}
          {isLoading && <MainLoader />}
        </div>
        {showErrorMessageModal && (
          <ErrorMessageAnimationModal
            show={showErrorMessageModal}
            message={errorMessage}
            onClose={() => {
              this.setState({ showErrorMessageModal: false });
            }}
          />
        )}
        {showSuccessMessageModal && (
          <SuccessMessageAnimationModal
            show={showSuccessMessageModal}
            message={successMessage}
            onClose={() => {
              this.setState({ showSuccessMessageModal: false });
            }}
          />
        )}
        {isFullScreenImage && (
          <Lightbox
            mainSrc={fullScreenImagePath}
            onCloseRequest={() =>
              this.setState({
                isFullScreenImage: false,
                fullScreenImagePath: "",
              })
            }
          />
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  symbol: getCurrencySymbol(),
  isInquiry: state.settings.isInquiry,
});

export default connect(mapStateToProps, {
  notificationCounter: getNotificationCounter,
  counter: getCommonCounter,
  isInquiryOrCart: isInquiryOrCart,
})(withRouter(EditOrder));
