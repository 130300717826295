import React from "react";
import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  getMasterDetailsId,
  getWishlist,
  isInquiryOrCart,
} from "../../actions";
import ErrorMessageAnimationModal from "../../animation/ErrorMessageAnimationModal";
import MainLoader from "../../animation/MainLoader";
import NoSpecialProductFoundAnim from "../../animation/NoSpecialProductFoundAnim";
import { getCall } from "../../api/post";
import {
  getImageUrl,
  getPlaceHolderImage,
  getWishListProductUrl,
} from "../../services";
import {
  getCurrencySymbol,
  getOpenStoreIdApp,
  getValueFromLanguageObject,
} from "../../store";
import {
  clearLocalStorageReload,
  getWishListId,
  getWishListName,
  navigateTo,
  removeUserDetailAndOtherDetails,
} from "../../utils/utils";
import ProductLoaderMain from "../layouts/contentloader/product-loader";

class ShareWishListPrintView extends Component {
  state = {
    skip: 0,
    limit: 20,
    isContentLoaderVisible: false,
    wishListProduct: [],
    isPaginationEnableForProductList: false,
  };

  componentDidMount() {
    const { masterDetailsObj } = this.props || {};

    if (!masterDetailsObj) {
      this.props.initMasterDetailsId();
    }
  }

  componentDidUpdate(prevProps) {
    const { wishlist: prevWishlist } = prevProps || {};
    const { wishlist, isInquiry, masterDetailsObj, match } = this.props || {};
    const { params } = match || {};
    const { id } = params || {};
    let openStoreId;
    if (!openStoreId) {
      openStoreId = getOpenStoreIdApp();
    }
    if (id && !wishlist) {
      this.props.getWishlist(id);
    }
    if (prevWishlist !== wishlist) {
      this.initWishListProducts();
    }
    if (!isInquiry && masterDetailsObj) {
      this.props.isInquiryOrCart(masterDetailsObj);
    }
  }

  initWishListProducts = async () => {
    try {
      this.setState({ isContentLoaderVisible: true });
      const { wishlist } = this.props || {};
      const { wishListProduct, limit, skip } = this.state || {};
      const wishListId = getWishListId(wishlist);
      const wishListProductUrl = getWishListProductUrl(
        wishListId,
        null,
        null,
        skip,
        limit
      );
      const wishListProductResponse = await getCall(wishListProductUrl, []);
      this.setState({ isContentLoaderVisible: false });
      const { error, data } = wishListProductResponse || {};
      if (error) {
        clearLocalStorageReload({ response: wishListProductResponse });
        const { message, statusCode } = error || {};
        this.loadErrorMessageAnimation(message);
        if (statusCode === 401) {
          removeUserDetailAndOtherDetails();
          navigateTo(this.props, `/login`);
          return;
        }
      } else {
        if (data && limit <= data.length) {
          this.setState({ isPaginationEnableForProductList: true });
        } else {
          this.setState({ isPaginationEnableForProductList: false });
        }
        if (skip > 0) {
          const pl = wishListProduct.concat(data);
          this.setState({ wishListProduct: pl });
        } else {
          this.setState({ wishListProduct: data });
        }
      }
    } catch (error) {
      console.log("error", error);
      this.setState({ isContentLoaderVisible: false });
    }
  };

  loadErrorMessageAnimation = (message) => {
    this.setState({ errorMessage: message, showErrorMessageModal: true });
  };

  getImageNameFromProduct = (product) => {
    const { product: productItem } = product || {};
    const { productmedia } = productItem || {};
    const [productmediaItem] = productmedia || [];
    const { productname: name } = productmediaItem || {};
    return name ? name : "";
  };

  renderProductImage = (product) => {
    const { masterDetailsObj } = this.props || {};
    const { product: productItem } = product || {};
    const { productmedia } = productItem || {};
    const [firstImage] = productmedia || {};
    const { productname } = firstImage || {};
    const imgUrl = getImageUrl(productname, masterDetailsObj);
    return (
      <img
        style={{ height: "150px" }}
        src={imgUrl}
        alt=""
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = getPlaceHolderImage();
        }}
      />
    );
  };

  render() {
    const {
      isContentLoaderVisible,
      wishListProduct,
      isLoading,
      showErrorMessageModal,
      errorMessage,
      isPaginationEnableForProductList,
    } = this.state || {};
    const { wishlist, symbol, isInquiry } = this.props || {};
    const strTitle = getValueFromLanguageObject("str_title") || "Title";
    const strPrice = getValueFromLanguageObject("str_price") || "Price";
    return (
      <>
        <div className="printlist">
          <div className="container-fluid">
            <div className="title">
              <h3 className="mt-4 mb-4">{getWishListName(wishlist)}</h3>
            </div>
            <div className="title-price">
              <span>{strTitle}</span>
              {!isInquiry && <span>{strPrice}</span>}
            </div>
            {wishListProduct && wishListProduct.length > 0 && (
              <InfiniteScroll
                dataLength={wishListProduct.length} //This is important field to render the next data
                next={this.fetchMoreItems}
                hasMore={isPaginationEnableForProductList}
                loader={<div className="loading-cls" />}
                endMessage={
                  ""
                  // <p className="seen-cls seen-it-cls">
                  //     <b>Yay! You have seen it all</b>
                  // </p>
                }
              >
                <div className="row">
                  {wishListProduct.map((product, index) => (
                    <div className="print-row mt-4" key={index}>
                      <div className="price">
                        {this.renderProductImage(product)}

                        <span className="ml-3">{product.product.name}</span>
                      </div>
                      {!isInquiry && (
                        <span className="mr-3">
                          {symbol}
                          {product.product.price}
                        </span>
                      )}
                    </div>
                  ))}
                </div>
              </InfiniteScroll>
            )}

            {isContentLoaderVisible && <ProductLoaderMain />}

            {wishListProduct &&
              wishListProduct.length === 0 &&
              !isContentLoaderVisible && <NoSpecialProductFoundAnim />}
          </div>
        </div>
        {isLoading && <MainLoader />}
        {showErrorMessageModal && (
          <ErrorMessageAnimationModal
            show={showErrorMessageModal}
            message={errorMessage}
            onClose={() => {
              this.setState({ showErrorMessageModal: false });
            }}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  symbol: getCurrencySymbol(),
  wishlist: state.settings.wishlist,
  isInquiry: state.settings.isInquiry,
  masterDetailsObj: state.masterDetails.masterDetailsObj,
});

export default connect(
  mapStateToProps,
  {
    isInquiryOrCart: isInquiryOrCart,
    getWishlist: getWishlist,
    initMasterDetailsId: getMasterDetailsId,
  }
)(withRouter(ShareWishListPrintView));
