import React, { Component } from "react";
import { connect } from "react-redux";
import { getImageUrl, getPlaceHolderImage } from "../../../services";

const imageContainer = {
  height: "100%",
  width: "100%",
  objectFit: "cover",
  border: "1px solid #D3D3D3",
};

class ImageWrapper extends Component {
  render() {
    const { imgUrl, masterDetailsObj, className } = this.props || {};
    const src = getImageUrl(imgUrl, masterDetailsObj);
    return (
      <div>
        <img
          style={imageContainer}
          className={className}
          src={src}
          alt=""
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = getPlaceHolderImage();
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  masterDetailsObj: state.masterDetails.masterDetailsObj,
});

export default connect(
  mapStateToProps,
  {}
)(ImageWrapper);
