import React, { Component } from "react";
import "../../components/wishlist/wishlist.css";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  getOpenStoreIdApp,
  getValueFromLanguageObject,
  loadUserDetailsFromLocalStorage,
} from "../../store";
import {
  clearLocalStorageReload,
  getWishListId,
  getWishListName,
  isOpenStoreIdExist,
  navigateTo,
  openLinkInNewTab,
  removeUserDetailAndOtherDetails,
} from "../../utils/utils";
import { getWishListProductUrl } from "../../services";
import { getCall, postCall } from "../../api/post";
import WishFilterBar from "../collection/common/wish-filter-bar";
import WishProductListing from "../collection/common/wish-product-listing";
import ProductLoaderMain from "../layouts/contentloader/product-loader";
import NoSpecialProductFoundAnim from "../../animation/NoSpecialProductFoundAnim";
import MainLoader from "../../animation/MainLoader";
import ErrorMessageAnimationModal from "../../animation/ErrorMessageAnimationModal";
import SuccessMessageAnimationModal from "../../animation/SuccessMessageAnimationModal";
import AddToCartAnim from "../../animation/AddToCartAnim";
import {
  getCommonCounter,
  getMasterDetailsId,
  getNotificationCounter,
  getUpdateQuantity,
  getWishlist,
  isInquiryOrCart,
} from "../../actions";

class SharedWishList extends Component {
  state = {
    wishListProduct: [],
    sortingOrder: 0,
    showErrorMessageModal: false,
    errorMessage: "",
    showSuccessMessageModal: false,
    successMessage: "",
    skip: 0,
    limit: 20,
    isContentLoaderVisible: false,
    isShareWishListOpen: false,
  };

  componentDidMount() {
    const { isInquiry, masterDetailsObj } = this.props || {};
    if (!masterDetailsObj) {
      this.props.initMasterDetailsId();
    }
    if (!isInquiry && masterDetailsObj) {
      this.props.isInquiryOrCart(masterDetailsObj);
    }
  }

  componentDidUpdate(prevProps) {
    let openStoreId;
    if (!openStoreId) {
      openStoreId = getOpenStoreIdApp();
    }
    const { wishlist: prevWishlist } = prevProps || {};
    const { wishlist, match } = this.props || {};
    const { params } = match || {};
    const { id } = params || {};
    if (id && !wishlist && openStoreId) {
      this.props.getWishlist(id);
    }
    if (prevWishlist !== wishlist && openStoreId) {
      this.initWishListProducts();
    }
  }

  initWishListProducts = async () => {
    try {
      this.setState({ isContentLoaderVisible: true });
      const { wishlist } = this.props || {};
      const { wishListProduct, searchValue, sortingOrder, limit, skip } =
        this.state || {};
      const wishListId = getWishListId(wishlist);
      if (!wishListId) {
        this.setState({ isContentLoaderVisible: false });
        return;
      }
      const wishListProductUrl = getWishListProductUrl(
        wishListId,
        searchValue,
        sortingOrder,
        skip,
        limit
      );
      const wishListProductResponse = await getCall(wishListProductUrl, []);
      this.setState({ isContentLoaderVisible: false });
      const { error, data } = wishListProductResponse || {};
      if (error) {
        clearLocalStorageReload({ response: wishListProductResponse });
        const { message, statusCode } = error || {};
        this.loadErrorMessageAnimation(message);
        if (statusCode === 401) {
          removeUserDetailAndOtherDetails();
          navigateTo(this.props, `/login`);
          return;
        }
      } else {
        if (data && limit <= data.length) {
          this.setState({ isPaginationEnableForProductList: true });
        } else {
          this.setState({ isPaginationEnableForProductList: false });
        }
        if (skip > 0) {
          const pl = wishListProduct.concat(data);
          this.setState({ wishListProduct: pl });
        } else {
          this.setState({ wishListProduct: data });
        }
      }
    } catch (error) {
      console.log("error", error);
      this.setState({ isContentLoaderVisible: false });
    }
  };

  onSearchProduct = (value) => {
    this.setState(
      {
        searchValue: value,
        skip: 0,
        productList: [],
      },
      () => {
        this.initWishListProducts();
      }
    );
  };

  handleSortingProductFilter = (order) => {
    this.setState(
      {
        sortingOrder: order,
        skip: 0,
        isContentLoaderVisible: true,
        wishListProduct: [],
      },
      () => {
        this.initWishListProducts().then(() => {
          this.setState({ isLoading: false });
        });
      }
    );
  };

  LayoutViewClicked = () => {};

  openFilter = () => {
    document.querySelector(".collection-filter").style = "left: -15px";
  };

  handlePagination = () => {
    const { wishListProduct } = this.state || {};
    const skip = wishListProduct.length;
    this.setState({ skip }, () => {
      this.initWishListProducts();
    });
  };

  addToCart = async (product, qty) => {
    if (qty === "") {
      return;
    }
    const { isInquiry, counter, updateQuantity } = this.props;
    try {
      if (
        product &&
        product.productvariation &&
        product.productvariation !== null &&
        product.productvariation !== "[]"
      ) {
        navigateTo(this.props, `/singleproduct/${product.id}`);
        return;
      }

      this.setState({ isLoading: true });
      let userDetails = loadUserDetailsFromLocalStorage();
      if (!isOpenStoreIdExist() && userDetails === undefined) {
        return;
      } else {
        userDetails = userDetails === undefined ? {} : userDetails;
      }
      const { id, cityId } = userDetails;
      const openStoreId = getOpenStoreIdApp();
      let userId = openStoreId ? openStoreId : id;

      const addtocartUrl = isInquiry
        ? "orders/productInquiry"
        : "orders/addtocart";
      var addtocartObj = {
        orderstatus: 1,
        inshoppingcart: 1,
        orderdetails: [
          {
            quantity: qty,
            productId: product.id,
          },
        ],
        userId,
        cityId: cityId,
        date: new Date(),
      };
      if (!isInquiry) {
        const totalAmountObj = { totalamount: product.price * parseFloat(qty) };
        addtocartObj = { ...addtocartObj, ...totalAmountObj };
      }
      const addToCartResponse = await postCall(addtocartUrl, addtocartObj);
      this.setState({ isLoading: false });
      if (addToCartResponse.error) {
        clearLocalStorageReload({ response: addToCartResponse });
        this.loadErrorMessageAnimation(addToCartResponse.error.message);
        if (addToCartResponse.error.statusCode === 401) {
          removeUserDetailAndOtherDetails();
          navigateTo(this.props, `/login`);
          return;
        }
      } else {
        if (isInquiry) {
          const strAddToInquirySuccessfully =
            getValueFromLanguageObject("str_add_to_inquiry_successfully") ||
            "Product inquiry added successfully";
          this.loadAddToCartMessageAnimation(strAddToInquirySuccessfully);
        } else {
          const strAddToCartSuccessfully =
            getValueFromLanguageObject("str_add_to_cart_successfully") ||
            "Product added to cart successfully";
          this.loadAddToCartMessageAnimation(strAddToCartSuccessfully);
        }
      }
      counter();
      updateQuantity();
    } catch (error) {
      this.setState({ isLoading: false });
    }
  };

  handleClickOfQuickView = () => {};

  loadErrorMessageAnimation = (message) => {
    this.setState({ errorMessage: message, showErrorMessageModal: true });
  };

  loadSuccessMessageAnimation = (message) => {
    this.setState({ successMessage: message, showSuccessMessageModal: true });
  };

  loadAddToCartMessageAnimation = (message) => {
    this.setState({
      addToCartMessage: message,
      showAddToCartMessageModal: true,
    });
  };

  render() {
    const {
      wishListProduct,
      showErrorMessageModal,
      errorMessage,
      isPaginationEnableForProductList,
      limit,
      isContentLoaderVisible,
      showSuccessMessageModal,
      successMessage,
      isLoading,
      showAddToCartMessageModal,
      addToCartMessage,
    } = this.state || {};
    const { wishlist } = this.props || {};

    return (
      <>
        <div className="collection-content">
          <div
            style={{ paddingTop: "70px", paddingBottom: "70px" }}
            className="collection-content container col"
          >
            <div className="page-main-content ">
              <div className="">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="top-banner-wrapper" />
                    <div className="collection-product-wrapper">
                      <div className="wishlist-header">
                        <div className="wishlist-title">
                          <h4>{getWishListName(wishlist)}</h4>
                        </div>
                        <div className="wishlist-share">
                          <ul>
                            <li>
                              <i
                                class="fa fa-print"
                                onClick={() => {
                                  const path = window.location.href.replace(
                                    "shared-wishlist",
                                    "shared-wishlist-print"
                                  );
                                  openLinkInNewTab(path);
                                }}
                              />
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="product-top-filter">
                        <div className="container-fluid p-0">
                          <div className="row">
                            <div className="col-12">
                              <WishFilterBar
                                productList={wishListProduct}
                                handleSortingProductFilter={
                                  this.handleSortingProductFilter
                                }
                                onLayoutViewClicked={(colmuns) =>
                                  this.LayoutViewClicked(colmuns)
                                }
                                onSearchProduct={this.onSearchProduct}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      {wishListProduct.length > 0 && (
                        <WishProductListing
                          productList={wishListProduct}
                          colSize={3}
                          handlePagination={this.handlePagination}
                          limit={limit}
                          isPaginationEnableForProductList={
                            isPaginationEnableForProductList
                          }
                          addToCart={this.addToCart}
                          handleClickOfQuickView={this.handleClickOfQuickView}
                          onClickOfDeleteWishlist={this.onClickOfDeleteWishlist}
                          isVisibleDeleteWishlist={false}
                        />
                      )}

                      {isContentLoaderVisible && <ProductLoaderMain />}

                      {wishListProduct.length === 0 &&
                        !isContentLoaderVisible && (
                          <NoSpecialProductFoundAnim />
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {isLoading && <MainLoader />}
        {showErrorMessageModal && (
          <ErrorMessageAnimationModal
            show={showErrorMessageModal}
            message={errorMessage}
            onClose={() => {
              this.setState({ showErrorMessageModal: false });
            }}
          />
        )}

        {showSuccessMessageModal && (
          <SuccessMessageAnimationModal
            show={showSuccessMessageModal}
            message={successMessage}
            onClose={() => {
              this.setState({ showSuccessMessageModal: false });
            }}
          />
        )}
        {showAddToCartMessageModal && (
          <AddToCartAnim
            show={showAddToCartMessageModal}
            message={addToCartMessage}
            onClose={() => {
              this.setState({ showAddToCartMessageModal: false });
            }}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  wishlist: state.settings.wishlist,
  isInquiry: state.settings.isInquiry,
});

export default connect(
  mapStateToProps,
  {
    counter: getCommonCounter,
    notificationCounter: getNotificationCounter,
    isInquiryOrCart: isInquiryOrCart,
    updateQuantity: getUpdateQuantity,
    getWishlist: getWishlist,
    initMasterDetailsId: getMasterDetailsId,
  }
)(withRouter(SharedWishList));
