import moment from "moment";

export const removeUserDetailAndOtherDetails = () => {
  localStorage.removeItem("userDetails");
  localStorage.removeItem("apiToken");
  localStorage.removeItem("openStoreId");
  localStorage.removeItem("masterDetailsId");
  localStorage.removeItem("isStoreOpen");
};

export const isOpenStoreIdExist = () => {
  const openStoreId = localStorage.getItem("openStoreId");
  return openStoreId ? true : false;
};

export const navigateTo = (props, path) => {
  if (props) {
    props.history.push(path);
  }
};

export const getInstancedomain = () => {
  let domain =
    // window.location.host === "localhost:3001"
    //   ? "nutland.sufalam.live"
    //   :
    window.location.host;
  return domain;
};

export const getInstanceurl = () => {
  const instanceUrl = window.location.pathname.split("/")[1];
  return instanceUrl;
};

export function clearLocalStorageReload({ response }) {
  const { error } = response;
  const { statusCode = "" } = error || {};
  if (statusCode === 406) {
    localStorage.clear();
    window.location.reload();
  }
}

export const getCurrentDate = () => {
  const currentDate = moment(new Date()).format("YYYY-MM-DD hh:mm:ss");
  return currentDate;
};

export const getWishListName = (wishlist) => {
  const [wishlistDefault] = wishlist || [];
  const { name } = wishlistDefault || {};
  return name || "";
};

export const getWishListId = (wishlist) => {
  const [wishlistDefault] = wishlist || [];
  const { id } = wishlistDefault || {};
  return id || "";
};

export const openLinkInNewTab = (path) => {
  const win = window.open(path, "_blank");
  win.focus();
};
