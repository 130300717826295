import React, { Component } from "react";
import { connect } from "react-redux";
import { isInquiryOrCart } from "../../../actions";
import { getValueFromLanguageObject } from "../../../store";

class WishFilterBar extends Component {
  //List Layout View
  listLayout = (colSize) => {
    document.querySelector(".collection-grid-view").style = "opacity:0";
    document.querySelector(".product-wrapper-grid").style = "opacity:0.2";
    document.querySelector(".product-wrapper-grid").classList.add("list-view");
    var elems = document.querySelector(".infinite-scroll-component .row")
      .childNodes;
    [].forEach.call(elems, function(el) {
      el.className = "";
      el.classList.add("col-lg-" + colSize);
    });
    setTimeout(function() {
      document.querySelector(".product-wrapper-grid").style = "opacity: 1";
    }, 500);
    this.props.onLayoutViewClicked(colSize);
  };
  // Layout Column View
  LayoutView = (colSize) => {
    if (
      !document
        .querySelector(".product-wrapper-grid")
        .classList.contains("list-view")
    ) {
      var elems = document.querySelector(".infinite-scroll-component .row")
        .childNodes;
      [].forEach.call(elems, function(el) {
        el.className = "";
        el.classList.add("col-lg-" + colSize);
      });
    }
    this.props.onLayoutViewClicked(colSize);
  };

  //Grid Layout View
  gridLayout = (colSize) => {
    document.querySelector(".collection-grid-view").style = "opacity:1";
    document
      .querySelector(".product-wrapper-grid")
      .classList.remove("list-view");
    var elems = document.querySelector(".infinite-scroll-component .row")
      .childNodes;
    [].forEach.call(elems, function(el) {
      el.className = "";
      el.classList.add("col-lg-" + colSize);
    });
    this.props.onLayoutViewClicked(colSize);
  };

  render() {
    const { onSearchProduct, isInquiry } = this.props;

    const strSortAndFilter = getValueFromLanguageObject("str_sort_and_filter")
      ? getValueFromLanguageObject("str_sort_and_filter")
      : "Sort & Filter";
    const strPrice = getValueFromLanguageObject("str_price")
      ? getValueFromLanguageObject("str_price")
      : "Price";

    const strHighToLow = getValueFromLanguageObject("str_high_to_low")
      ? getValueFromLanguageObject("str_high_to_low")
      : "High to Low";

    const strLowToHigh = getValueFromLanguageObject("str_low_to_high")
      ? getValueFromLanguageObject("str_low_to_high")
      : "Low to High";

    const strSortByDate = getValueFromLanguageObject("str_sort_by_date")
      ? getValueFromLanguageObject("str_sort_by_date")
      : "Sort Date By";

    const strAscending = getValueFromLanguageObject("str_ascending")
      ? getValueFromLanguageObject("str_ascending")
      : "Ascending";

    const strDescending = getValueFromLanguageObject("str_descending")
      ? getValueFromLanguageObject("str_descending")
      : "Descending";

    const strFilterBy = getValueFromLanguageObject("str_filter_by")
      ? getValueFromLanguageObject("str_filter_by")
      : "Filter by";

    const strAllItems = getValueFromLanguageObject("str_all_items")
      ? getValueFromLanguageObject("str_all_items")
      : "All Items";

    const strPurchased = getValueFromLanguageObject("str_purchased")
      ? getValueFromLanguageObject("str_purchased")
      : "Purchased";

    return (
      <div className="product-filter-content">
        <div className="row search-product">
          <div className="col-sm-12 search-form subscribe-form justify-content-start">
            <input
              style={{ border: "1px solid #dddddd" }}
              type="text"
              className="form-control"
              id="search-product"
              placeholder={
                getValueFromLanguageObject("str_search_products")
                  ? getValueFromLanguageObject("str_search_products")
                  : "Search Item"
              }
              onChange={(e) => {
                onSearchProduct(e.target.value);
              }}
            />
            <i className="fa fa-search product-search no-cursor" />
          </div>
        </div>
        <div className="collection-view">
          <ul>
            <li>
              <i
                className="fa fa-th grid-layout-view"
                onClick={() => this.gridLayout(3)}
              />
            </li>
            <li>
              <i
                className="fa fa-list-ul list-layout-view"
                onClick={() => this.listLayout(12)}
              />
            </li>
          </ul>
        </div>
        <div className="collection-grid-view d-none">
          <ul>
            <li>
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/icon/2.png`}
                alt=""
                className="product-2-layout-view"
                onClick={() => this.LayoutView(6)}
              />
            </li>
            <li>
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/icon/3.png`}
                alt=""
                className="product-3-layout-view"
                onClick={() => this.LayoutView(4)}
              />
            </li>
            <li>
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/icon/4.png`}
                alt=""
                className="product-4-layout-view"
                onClick={() => this.LayoutView(3)}
              />
            </li>
            <li>
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/icon/6.png`}
                alt=""
                className="product-6-layout-view"
                onClick={() => this.LayoutView(2)}
              />
            </li>
          </ul>
        </div>
        <div
          style={{ border: "1px solid #dddddd", marginLeft: "11px" }}
          className="product-page-filter"
        >
          <select
            onChange={(e) =>
              this.props.handleSortingProductFilter(e.target.value)
            }
          >
            <option value="">{strSortAndFilter}</option>
            {!isInquiry && (
              <optgroup label={strPrice}>
                <option value="1">{strHighToLow}</option>
                <option value="2">{strLowToHigh}</option>
              </optgroup>
            )}
            <optgroup label={strSortByDate}>
              <option value="3">{strAscending}</option>
              <option value="4">{strDescending}</option>
            </optgroup>
            <optgroup label={strFilterBy}>
              <option value="5">{strAllItems}</option>
              <option value="6">{strPurchased}</option>
            </optgroup>
          </select>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isInquiry: state.settings.isInquiry,
});

export default connect(
  mapStateToProps,
  {
    isInquiryOrCart: isInquiryOrCart,
  }
)(WishFilterBar);
