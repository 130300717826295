import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { getCurrencySymbol } from "../../../store";
import { getCommonCounter, getUpdateQuantity } from "../../../actions";
import WishProductListItem from "./wish-product-list-item";

class WishProductListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showErrorMessageModal: false,
      errorMessage: "",
      showSuccessMessageModal: false,
      successMessage: "",
    };
  }
  componentWillMount() {
    this.fetchMoreItems();
  }

  fetchMoreItems = () => {
    this.props.handlePagination();
  };

  render() {
    const {
      productList,
      symbol,
      isPaginationEnableForProductList,
      handleClickOfQuickView,
      onClickOfDeleteWishlist,
      isVisibleDeleteWishlist,
    } = this.props;
    return (
      <>
        <div>
          <div className="product-wrapper-grid">
            <div className="container-fluid">
              {productList && productList.length > 0 ? (
                <InfiniteScroll
                  dataLength={productList.length} //This is important field to render the next data
                  next={this.fetchMoreItems}
                  hasMore={isPaginationEnableForProductList}
                  loader={<div className="loading-cls" />}
                  endMessage={
                    ""
                    // <p className="seen-cls seen-it-cls">
                    //     <b>Yay! You have seen it all</b>
                    // </p>
                  }
                >
                  <div className="row">
                    {productList.map((product, index) => (
                      <div
                        className={`${
                          this.props.colSize === 3
                            ? "col-xl-3 col-md-6 col-grid-box"
                            : "col-lg-" + this.props.colSize
                        }`}
                        key={index}
                      >
                        <WishProductListItem
                          product={product}
                          symbol={symbol}
                          onAddToCartClicked={this.props.addToCart}
                          key={index}
                          handleClickOfQuickView={handleClickOfQuickView}
                          onClickOfDeleteWishlist={onClickOfDeleteWishlist}
                          isVisibleDeleteWishlist={isVisibleDeleteWishlist}
                        />
                      </div>
                    ))}
                  </div>
                </InfiniteScroll>
              ) : (
                <div className="row">
                  <div className="col-sm-12 text-center section-b-space mt-5 no-found">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/empty-
                      .jpg`}
                      className="img-fluid mb-4"
                      alt=""
                    />
                    <h3>
                      Sorry! Couldn't find the product you were looking For!!!{" "}
                    </h3>
                    <p>
                      Please check if you have misspelt something or try
                      searching with other words.
                    </p>
                    <Link to={`/products`} className="btn btn-solid">
                      continue shopping
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  symbol: getCurrencySymbol(),
});

export default connect(
  mapStateToProps,
  {
    counter: getCommonCounter,
    updateQuantity: getUpdateQuantity,
  }
)(withRouter(WishProductListing));
