import React, { Component } from "react";
import Modal from "react-responsive-modal";
import ErrorMessageAnimationModal from "../../animation/ErrorMessageAnimationModal";
import MainLoader from "../../animation/MainLoader";
import SuccessMessageAnimationModal from "../../animation/SuccessMessageAnimationModal";
import { patchCall } from "../../api/post";
import { getEditWishListUrl } from "../../services";
import { getValueFromLanguageObject } from "../../store";
import {
  clearLocalStorageReload,
  getWishListId,
  getWishListName,
  navigateTo,
  removeUserDetailAndOtherDetails,
} from "../../utils/utils";

class EditWishListModal extends Component {
  state = {
    wishListName: "",
    showSuccessMessageModal: "",
    successMessage: "",
    showErrorMessageModal: "",
    errorMessage: "",
    isLoading: false,
  };

  componentDidMount() {
    const wishListName = getWishListName(this.props.wishlist);
    this.setState({ wishListName });
  }

  handleChangeOfEditWishList = (event) => {
    this.setState({ wishListName: event.target.value });
  };

  onClickOfEditWishList = async () => {
    try {
      this.setState({ isLoading: true });
      const { wishListName } = this.state;
      const { wishlist } = this.props || {};
      const wishListId = getWishListId(wishlist);
      const editWishListNameObj = {
        name: wishListName,
      };
      const editWishListUrl = getEditWishListUrl(wishListId);
      const editWishListResponse = await patchCall(
        editWishListUrl,
        editWishListNameObj
      );
      this.setState({ isLoading: false });
      if (editWishListResponse.error) {
        clearLocalStorageReload({ response: editWishListResponse });
        this.loadErrorMessageAnimation(editWishListResponse.error.message);
        if (editWishListResponse.error.statusCode === 401) {
          removeUserDetailAndOtherDetails();
          navigateTo(this.props, `/login`);
          return;
        }
      } else {
        const strWishListEditedSuccessMessage =
          getValueFromLanguageObject("str_wishlist_edited_successfully") ||
          "Wishlist edited successfully";
        this.loadSuccessMessageAnimation(strWishListEditedSuccessMessage);
        setTimeout(() => {
          const { onSuccessEditWishListNameModal } = this.props || {};
          onSuccessEditWishListNameModal();
        }, 3500);
      }
    } catch (error) {
      this.setState({ isLoading: false });
    }
  };

  loadErrorMessageAnimation = (message) => {
    this.setState({ errorMessage: message, showErrorMessageModal: true });
  };

  loadSuccessMessageAnimation = (message) => {
    this.setState({ successMessage: message, showSuccessMessageModal: true });
  };

  render() {
    const {
      showSuccessMessageModal,
      successMessage,
      showErrorMessageModal,
      errorMessage,
      isLoading,
      wishListName,
    } = this.state;

    const { isEditWishListNameOpen, onCloseEditWishListNameModal } = this.props;

    const strWishtList =
      getValueFromLanguageObject("str_edit_wishlist") || "Edit Wishlist";

    const strEnterWishListName =
      getValueFromLanguageObject("str_enter_wishlist_name") ||
      "Enter wishlist name";

    const strWishListName =
      getValueFromLanguageObject("str_wishlist_name") || "Wishlist Name";

    return (
      <>
        <Modal
          open={isEditWishListNameOpen}
          onClose={onCloseEditWishListNameModal}
          classNames={{
            overlay: "customOverlay",
            modal: "customModal",
          }}
          center
        >
          <div className="theme-form" style={{ margin: "20px" }}>
            <div className="form-group">
              <label htmlFor="edit-wishlist">{strWishListName}</label>
              <input
                id="edit-wishlist"
                type="text"
                onChange={this.handleChangeOfEditWishList}
                className="form-control"
                value={wishListName}
                placeholder={strEnterWishListName}
                maxLength={"64"}
              />
            </div>

            <div className="form-group">
              <button
                onClick={() => {
                  this.onClickOfEditWishList();
                }}
                disabled={wishListName.trim() === "" || isLoading}
                className="btn btn-solid"
              >
                {strWishtList}
              </button>
            </div>
          </div>
        </Modal>
        {isLoading && <MainLoader />}
        {showErrorMessageModal && (
          <ErrorMessageAnimationModal
            show={showErrorMessageModal}
            message={errorMessage}
            onClose={() => {
              this.setState({ showErrorMessageModal: false });
            }}
          />
        )}
        {showSuccessMessageModal && (
          <SuccessMessageAnimationModal
            show={showSuccessMessageModal}
            message={successMessage}
            onClose={() => {
              this.setState({ showSuccessMessageModal: false });
            }}
          />
        )}
      </>
    );
  }
}

export default EditWishListModal;
