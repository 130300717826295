import * as types from "../constants/ActionTypes";
import {
  getOpenStoreIdApp,
  isStoreOpen,
  loadUserDetailsFromLocalStorage,
} from "../store";
import { getCall } from "../api/post";
import {
  getSettingUrl,
  getMasterDetailsIdUrl,
  getWishlistUrl,
} from "../services";
import { clearLocalStorageReload } from "../utils/utils";

export const fetchProductsBegin = () => ({
  type: types.FETCH_PRODUCTS_BEGIN,
});

export const receiveProducts = (products) => ({
  type: types.RECEIVE_PRODUCTS,
  products,
});

export const fetchSingleProduct = (productId) => ({
  type: types.FETCH_SINGLE_PRODUCT,
  productId,
});

// Get Common Counter
export const getCommonCounter = () => async (dispatch) => {
  let cartCounter = 0;
  let userDetails = loadUserDetailsFromLocalStorage();
  if (isStoreOpen() === "false" && userDetails === undefined) {
    return cartCounter;
  } else {
    userDetails = userDetails === undefined ? {} : userDetails;
  }
  const { id } = userDetails;
  const openStoreId = getOpenStoreIdApp();
  const userId = openStoreId ? openStoreId : id;
  if (userId) {
    const counterUrl = "commoncounters?filter[where][userId]=" + userId;
    const commonCounter = await getCall(counterUrl, []);
    if (commonCounter.error) {
      clearLocalStorageReload({ response: commonCounter });
    } else {
      if (commonCounter.length > 0) {
        cartCounter = commonCounter[0].cart;
      }
    }
  }

  dispatch(getCommonCounterDispatcher(cartCounter));
};

export const getUpdateQuantity = () => (dispatch) => {
  const rand = Math.random() * 10;
  dispatch(getUpdateQuantityDispatcher(rand));
};

// Get Notification Counter
export const getNotificationCounter = () => async (dispatch) => {
  let notificationCounter = 0;
  let userDetails = loadUserDetailsFromLocalStorage();
  if (isStoreOpen() === "false" && userDetails === undefined) {
    return notificationCounter;
  } else {
    userDetails = userDetails === undefined ? {} : userDetails;
  }
  const { id } = userDetails;
  const openStoreId = getOpenStoreIdApp();
  const userId = openStoreId ? openStoreId : id;
  if (userId) {
    const counterUrl = "commoncounters?filter[where][userId]=" + userId;
    const commonCounter = await getCall(counterUrl, []);
    if (commonCounter.error) {
      clearLocalStorageReload({ response: commonCounter });
    } else {
      if (commonCounter.length > 0) {
        notificationCounter = commonCounter[0].notifications;
      }
    }
  }

  dispatch(getNotificationCounterDispatcher(notificationCounter));
};

// Is User Allowed to register or not
export const isUserAllowedToRegister = (masterDetailsObj) => async (
  dispatch
) => {
  let isRegisterAllow = false;
  const settingUrl = getSettingUrl(masterDetailsObj);
  const setting = await getCall(settingUrl, []).then();
  if (setting.error) {
    clearLocalStorageReload({ response: setting });
  } else {
    for (let i = 0; i < setting.length; i++) {
      if (setting[i].registerallow === "ALLOW" && setting[i].status === 1) {
        isRegisterAllow = true;
      }
    }
  }
  dispatch(isUserAllowedToRegisterDispatcher(isRegisterAllow));
};

// Get Inquiry Or Cart Setting
export const isInquiryOrCart = (masterDetailsObj) => async (dispatch) => {
  let isInquiry = false;
  const settingUrl = getSettingUrl(masterDetailsObj);
  const setting = await getCall(settingUrl, []);
  if (setting.error) {
    clearLocalStorageReload({ response: setting });
  } else {
    for (let i = 0; i < setting.length; i++) {
      if (setting[i].registerallow === "IS_INQUIRY") {
        if (setting[i].status === 1) {
          isInquiry = true;
        }
      }
    }
  }
  dispatch(isInquiryOrCartDispatcher(isInquiry));
};

// Get Wishlist
export const getWishlist = (userId) => async (dispatch) => {
  let wishlist = [];
  const wishlistUrl = getWishlistUrl(userId);
  const getWishlistResponse = await getCall(wishlistUrl, []);
  if (getWishlistResponse.error) {
    clearLocalStorageReload({ response: getWishlistResponse });
  } else {
    wishlist = getWishlistResponse;
  }
  dispatch(wishlistDispatcher(wishlist));
};

export const getMasterDetailsId = () => async (dispatch) => {
  let masterDetailsObj = {};
  const publicUrl = window.location.pathname.split("/")[1];
  const masterDetailsIdUrl = getMasterDetailsIdUrl(publicUrl);
  const masterDetails = await getCall(masterDetailsIdUrl, []);
  if (masterDetails.error) {
    clearLocalStorageReload({ response: masterDetails });
  } else {
    if (masterDetails) {
      masterDetailsObj = masterDetails;
    }
  }
  dispatch(getMasterDetailsIdDispatcher(masterDetailsObj));
};

// Set Current Language
export const initLanguage = (locale) => (dispatch) => {
  dispatch(initLanguageDispatcher(locale));
};

export const setBaseCurrency = (baseCurrency) => (dispatch) => {
  dispatch(setBaseCurrencyDispatcher(baseCurrency));
};

export const getCommonCounterDispatcher = (cartCounter) => ({
  type: types.COMMON_COUNTER,
  cartCounter,
});

export const getUpdateQuantityDispatcher = (updateQuantityRandom) => ({
  type: types.UPDATE_QTY,
  updateQuantity: updateQuantityRandom,
});

export const getNotificationCounterDispatcher = (notificationCounter) => ({
  type: types.NOTIFICATION_COUNTER,
  notificationCounter,
});

export const isUserAllowedToRegisterDispatcher = (isRegisterAllow) => ({
  type: types.IS_REGISTER_ALLOW,
  isRegisterAllow,
});
export const getMasterDetailsIdDispatcher = (masterDetailsObj) => ({
  type: types.MASTER_DETAILS_ID,
  masterDetailsObj,
});

export const isInquiryOrCartDispatcher = (isInquiry) => ({
  type: types.IS_INQUIRY,
  isInquiry,
});

export const wishlistDispatcher = (wishlist) => ({
  type: types.WISHLIST,
  wishlist,
});

export const initLanguageDispatcher = (language) => ({
  type: types.CURRENT_LANGUGAE,
  language,
});

export const addToCartAndRemoveWishlist = (product, qty) => (dispatch) => {
  dispatch(addToCartUnsafe(product, qty));
  dispatch(removeFromWishlist(product));
};
export const addToCartUnsafe = (product, qty) => ({
  type: types.ADD_TO_CART,
  product,
  qty,
});
export const removeFromCart = (product_id) => (dispatch) => {
  dispatch({
    type: types.REMOVE_FROM_CART,
    product_id,
  });
};
export const incrementQty = (product, qty) => (dispatch) => {
  dispatch(addToCartUnsafe(product, qty));
};
export const decrementQty = (productId) => (dispatch) => {
  dispatch({
    type: types.DECREMENT_QTY,
    productId,
  });
};

//it seems that I should probably use this as the basis for "Wishlist"
export const addToWishlist = (product) => (dispatch) => {
  dispatch(addToWishlistUnsafe(product));
};
export const addToWishlistUnsafe = (product) => ({
  type: types.ADD_TO_WISHLIST,
  product,
});
export const removeFromWishlist = (product_id) => (dispatch) => {
  dispatch({
    type: types.REMOVE_FROM_WISHLIST,
    product_id,
  });
};

//Compare Products
export const addToCompare = (product) => (dispatch) => {
  dispatch(addToCompareUnsafe(product));
};
export const addToCompareUnsafe = (product) => ({
  type: types.ADD_TO_COMPARE,
  product,
});
export const removeFromCompare = (product_id) => ({
  type: types.REMOVE_FROM_COMPARE,
  product_id,
});

// Filters
export const filterBrand = (brand) => ({
  type: types.FILTER_BRAND,
  brand,
});
export const filterColor = (color) => ({
  type: types.FILTER_COLOR,
  color,
});
export const filterPrice = (value) => ({
  type: types.FILTER_PRICE,
  value,
});
export const filterSort = (sort_by) => ({
  type: types.SORT_BY,
  sort_by,
});

// Currency
export const changeCurrency = (symbol) => ({
  type: types.CHANGE_CURRENCY,
  symbol: symbol,
});

export const setBaseCurrencyDispatcher = (baseCurrency) => ({
  type: types.BASE_CURRENCY,
  baseCurrency,
});
