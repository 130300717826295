import React, { Component } from "react";
import { Helmet } from "react-helmet";
import "../../common/index.scss";
import Slider from "react-slick";

// Import custom components
import Header from "../header/header";
import SpecialProducts from "./special-products";
import {
  loadUserDetailsFromLocalStorage,
  getValueFromLanguageObject,
  getCurrencySymbol,
  getOpenStoreIdApp,
} from "../../../store/index";
import { getCall, postCall } from "../../../api/post";
import MainFooter from "../../layouts/footer/main_footer";
import {
  getImageUrl,
  getPlaceHolderImage,
  getSettingUrl,
  getOfferBannerUrl,
  getMerchantInformationUrl,
  getCatalogueJewellaryUrl,
  getPlaceHolderBanner,
  getAddToWishlistUrl,
} from "../../../services";
import NotificationList from "../notifications/notification-list";
import Drawer from "@material-ui/core/Drawer";
import { connect } from "react-redux";
import {
  getCommonCounter,
  getNotificationCounter,
  getUpdateQuantity,
  getWishlist,
  isInquiryOrCart,
} from "../../../actions";
import { NotificationContainer } from "react-notifications";

import ThemeSettings from "../../common/theme-settings";
import { isMobile } from "react-device-detect";
import MainLoader from "../../../animation/MainLoader";
import ErrorMessageAnimationModal from "../../../animation/ErrorMessageAnimationModal";
import {
  clearLocalStorageReload,
  isOpenStoreIdExist,
  navigateTo,
  removeUserDetailAndOtherDetails,
} from "../../../utils/utils";
import { withRouter } from "react-router-dom";
import { debuglogger } from "../../../utils/logger";
import AddToCartAnim from "../../../animation/AddToCartAnim";
import QuickViewProductDetailsModal from "./quick-view-product-details-modal";
import AddToWishListAnim from "../../../animation/AddToWishListAnim";

const notificationHeaderStyle = {
  textTransform: "uppercase",
  fontSize: "20px",
  padding: "15px",
  fontWeight: "400",
  color: "#222222",
  backgroundColor: "#F0F0F0",
};

class Dashboard extends Component {
  state = {
    productsNewArrival: [],
    productsBestSelling: [],
    isDrawerVisible: false,
    isLoading: false,
    offerBanners: [],
    AppTitle: "",
    currentCatelogueType: {},
    showErrorMessageModal: false,
    errorMessage: "",
    isContentLoaderVisible: false,
    showAddToCartMessageModal: false,
    addToCartMessage: "",
    isQuickViewProductDetailsModalShow: false,
  };

  componentDidMount() {
    // document
    //   .getElementById("color")
    //   .setAttribute("href", `${process.env.PUBLIC_URL}/assets/css/color7.css`);

    const userDetails = loadUserDetailsFromLocalStorage();
    if (!isOpenStoreIdExist() && userDetails === undefined) {
      this.props.history.push(`/login`);
      return;
    }
    this.initMerchantInfo();
    this.initNewAndBestSellingProducts();
    this.initOfferBanner();
    this.initCatelogueSettings();
    const { masterDetailsObj } = this.props;
    this.props.isInquiryOrCart(masterDetailsObj);
    const { id } = userDetails || {};
    if (id) {
      this.props.getWishlist(id);
    }
    this.props.notificationCounter();
  }

  initNewAndBestSellingProducts = () => {
    this.setState({ isLoading: true }, () => {
      this.initProductListNewArrival();
      this.initProductListBestSelling().then(() => {
        this.setState({ isLoading: false });
      });
    });
  };

  initProductListNewArrival = async () => {
    const productListUrl =
      "products?filter[include]=productbrand&filter[include]=productmedia&filter[order][0]=created DESC&filter[include]=category&filter[where][productstatus]=1&filter[where][availablequantity][gt]=0&filter[skip]=0&filter[limit]=20";
    debuglogger("productListUrl", productListUrl);
    const products = await getCall(productListUrl, []);
    if (products.error) {
      clearLocalStorageReload({ response: products });
      this.loadErrorMessageAnimation(products.error.message);
      if (products.error.statusCode === 401) {
        removeUserDetailAndOtherDetails();
        navigateTo(this.props, `/login`);
        return;
      }
    } else {
      this.setState({ productsNewArrival: products });
    }
  };

  initMerchantInfo = async () => {
    const { masterDetailsObj } = this.props;
    const merchantInfoUrl = getMerchantInformationUrl(masterDetailsObj);
    debuglogger("merchantInfoUrl", merchantInfoUrl);
    const merchantInfo = await getCall(merchantInfoUrl, []);
    if (merchantInfo.error) {
      clearLocalStorageReload({ response: merchantInfo });
      this.loadErrorMessageAnimation(merchantInfo.error.message);
      this.setState({
        isMerchantGSTEnable: false,
        merchantInfo: {},
        AppTitle: "",
      });
    } else {
      this.setState({
        merchantInfo,
        AppTitle: merchantInfo.companyname,
        isMerchantGSTEnable: merchantInfo.enablegst === 0 ? false : true,
      });
    }
  };

  initCatelogueSettings = async () => {
    const { masterDetailsObj } = this.props;
    const settingUrl = getSettingUrl(masterDetailsObj);
    debuglogger("settingUrl", settingUrl);
    const settingInfo = await getCall(settingUrl, []);
    if (settingInfo.error) {
      clearLocalStorageReload({ response: settingInfo });
      this.loadErrorMessageAnimation(settingInfo.error.message);
    } else {
      let settings = {};
      settingInfo.forEach((setting) => {
        if (
          (setting.registerallow === "Catalogue_FMCG" ||
            setting.registerallow === "Catalogue_Jewellary" ||
            setting.registerallow === "Catalogue_Electronics" ||
            setting.registerallow === "Catalogue_FABRIC") &&
          setting.status === 1
        ) {
          settings = setting;
        }
      });
      if (settings && settings.registerallow === "Catalogue_Jewellary") {
        this.initGoldPriceJwelleryCatelogue();
      }
    }
  };

  initSingleCategry = async (product) => {
    const { category } = product || {};
    const [firstCategory] = category || [];
    const { category: ctr } = firstCategory || {};
    const { parentId } = ctr || {};
    if (parentId) {
      const singleCategoryUrl = "categories/" + parentId;
      const singleCategory = await getCall(singleCategoryUrl, []);
      if (singleCategory.error) {
        clearLocalStorageReload({ response: singleCategory });
        this.loadErrorMessageAnimation(singleCategory.error.message);
        if (singleCategory.error.statusCode === 401) {
          removeUserDetailAndOtherDetails();
          navigateTo(this.props, `/login`);
          return;
        }
      } else {
        this.setState({ category: singleCategory });
      }
    }
  };

  // Best Selling
  initProductListBestSelling = async () => {
    const productListUrl =
      "products?filter[include]=productbrand&filter[include]=productmedia&filter[include]=category&filter[where][productstatus]=1&filter[where][availablequantity][gt]=0&filter[order][0]=sellcounter DESC&filter[skip]=0&filter[limit]=20";
    debuglogger("productListUrl", productListUrl);
    const products = await getCall(productListUrl, []);
    if (products.error) {
      clearLocalStorageReload({ response: products });
      this.loadErrorMessageAnimation(products.error.message);
      if (products.error.statusCode === 401) {
        removeUserDetailAndOtherDetails();
        navigateTo(this.props, `/login`);
        return;
      }
    } else {
      this.setState({ productsBestSelling: products });
    }
  };

  initOfferBanner = async () => {
    try {
      const { masterDetailsObj } = this.props;
      const offerBannerUrl = getOfferBannerUrl(masterDetailsObj);
      debuglogger("offerBannerUrl", offerBannerUrl);
      const offerBanners = await getCall(offerBannerUrl, []);
      if (offerBanners.error) {
        clearLocalStorageReload({ response: offerBanners });
        this.loadErrorMessageAnimation(offerBanners.error.message);
        if (offerBanners.error.statusCode === 401) {
          removeUserDetailAndOtherDetails();
          navigateTo(this.props, `/login`);
          return;
        }
      } else {
        // const banner = offerBanners.map((banner) => {
        //   return banner.media.length > 0 ? banner.media[0].image : "";
        // });
        this.setState({ offerBanners });
      }
    } catch (error) {
      this.setState({ offerBanners: [] });
    }
  };

  initGoldPriceJwelleryCatelogue = async () => {
    const { masterDetailsObj } = this.props;
    const goldPriceUrl = getCatalogueJewellaryUrl(masterDetailsObj);
    debuglogger("goldPriceUrl", goldPriceUrl);
    const goldPrice = await getCall(goldPriceUrl, []);
    if (goldPrice.error) {
      clearLocalStorageReload({ response: goldPrice });
      this.loadErrorMessageAnimation(goldPrice.error.message);
    } else {
      if (goldPrice.length > 0 && goldPrice[0].status === 1) {
        this.setState({ goldPrice: goldPrice[0].text });
      }
    }
  };

  handleNotificicationDrawer = () => {
    const { isDrawerVisible } = this.state;
    this.setState({ isDrawerVisible: !isDrawerVisible });
  };

  onClickOnSettingsIcon = () => {
    this.props.history.push(`/settings`);
  };

  onCloseofNotificationDrawer = () => {
    this.setState({ isDrawerVisible: false });
    this.props.notificationCounter();
  };

  loadErrorMessageAnimation = (message) => {
    this.setState({ errorMessage: message, showErrorMessageModal: true });
  };

  loadAddToCartMessageAnimation = (message) => {
    this.setState({
      addToCartMessage: message,
      showAddToCartMessageModal: true,
    });
  };

  loadAddToWishListMessageAnimation = (message) => {
    this.setState({
      addToWishListMessage: message,
      showAddToWishListMessageModal: true,
    });
  };

  showQuickViewProductDetailsModal = () => {
    this.setState({ isQuickViewProductDetailsModalShow: true });
  };

  hideQuickViewProductDetailsModal = () => {
    this.setState({ isQuickViewProductDetailsModalShow: false });
  };

  onClickOfOfferBanner = (banner) => {
    const { link: links } = banner;

    links.map((linkItem, index) => {
      const { productId, categoryId, link } = linkItem;
      if (productId) {
        this.props.history.push(`/singleproduct/${productId}`);
      }
      if (categoryId) {
        this.props.history.push(`/c/${categoryId}`);
      }
      if (link) {
        window.open(link, "_blank");
      }
      return null;
    });
  };

  renderOfferBanners = () => {
    const { offerBanners } = this.state;
    const { masterDetailsObj } = this.props;

    return (
      <>
        <Slider className="slide-1 home-slider">
          {offerBanners &&
            offerBanners.length > 0 &&
            offerBanners.map((banner, i) => {
              const { media: medias } = banner;
              return medias.map((media, index) => {
                const { image } = media;
                return (
                  <div
                    key={i}
                    onClick={() => {
                      this.onClickOfOfferBanner(banner);
                    }}
                  >
                    <div className="home">
                      <img
                        src={getImageUrl(image, masterDetailsObj)}
                        alt=""
                        className="img-fluid cursor-pointer"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = getPlaceHolderBanner();
                        }}
                      />
                    </div>
                  </div>
                );
              });
            })}
          {offerBanners.length === 0 && (
            <div>
              <div className="home">
                <img
                  style={{
                    width: "100%",
                  }}
                  src={getPlaceHolderBanner()}
                  alt=""
                  className="img-fluid cursor-pointer"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = getPlaceHolderImage();
                  }}
                />
              </div>
            </div>
          )}
        </Slider>
      </>
    );
  };

  addToCart = async (product, qty) => {
    if (qty === "") {
      return;
    }
    const { isInquiry, counter, updateQuantity } = this.props;
    try {
      debuglogger("product", product);
      if (
        product &&
        product.productvariation &&
        product.productvariation !== null &&
        product.productvariation !== "[]"
      ) {
        navigateTo(this.props, `/singleproduct/${product.id}`);
        return;
      }

      // this.props.handleLoader(true);
      this.setState({ isLoading: true });
      let userDetails = loadUserDetailsFromLocalStorage();
      if (!isOpenStoreIdExist() && userDetails === undefined) {
        return;
      } else {
        userDetails = userDetails === undefined ? {} : userDetails;
      }
      const { id, cityId } = userDetails;
      const openStoreId = getOpenStoreIdApp();
      let userId = openStoreId ? openStoreId : id;

      const addtocartUrl = isInquiry
        ? "orders/productInquiry"
        : "orders/addtocart";
      var addtocartObj = {
        orderstatus: 1,
        inshoppingcart: 1,
        orderdetails: [
          {
            quantity: qty,
            productId: product.id,
          },
        ],
        userId,
        cityId: cityId,
        date: new Date(),
      };
      if (!isInquiry) {
        const totalAmountObj = { totalamount: product.price * qty };
        addtocartObj = { ...addtocartObj, ...totalAmountObj };
      }

      const addToCartResponse = await postCall(addtocartUrl, addtocartObj);
      this.setState({ isLoading: false });
      if (addToCartResponse.error) {
        clearLocalStorageReload({ response: addToCartResponse });
        this.loadErrorMessageAnimation(addToCartResponse.error.message);
        if (addToCartResponse.error.statusCode === 401) {
          removeUserDetailAndOtherDetails();
          navigateTo(this.props, `/login`);
          return;
        }
      } else {
        if (isInquiry) {
          this.loadAddToCartMessageAnimation(
            getValueFromLanguageObject("str_add_to_inquiry_successfully")
              ? getValueFromLanguageObject("str_add_to_inquiry_successfully")
              : "Product inquiry added successfully"
          );
        } else {
          this.loadAddToCartMessageAnimation(
            getValueFromLanguageObject("str_add_to_cart_successfully")
              ? getValueFromLanguageObject("str_add_to_cart_successfully")
              : "Product added to cart successfully"
          );
        }
        this.hideQuickViewProductDetailsModal();
      }
      counter();
      updateQuantity();
      this.initNewAndBestSellingProducts();
    } catch (error) {
      this.setState({ isLoading: false });
    }
  };

  onAddToWishlistClicked = async (product) => {
    try {
      this.setState({ isLoading: true });
      const { wishlist } = this.props || {};
      const [wishlistDefault] = wishlist || {};
      let userDetails = loadUserDetailsFromLocalStorage();
      const { id: userId } = userDetails || {};
      const { id: productId } = product || {};
      const addtowishlistObj = {
        wishlistId: wishlistDefault.id,
        userId,
        productId,
      };
      const addToWishlistUrl = getAddToWishlistUrl();
      const addToCartResponse = await postCall(
        addToWishlistUrl,
        addtowishlistObj
      );
      const { error } = addToCartResponse || {};
      this.setState({ isLoading: false });
      if (error) {
        clearLocalStorageReload({ response: addToCartResponse });
        this.loadErrorMessageAnimation(error.message);
        if (error.statusCode === 401) {
          removeUserDetailAndOtherDetails();
          navigateTo(this.props, `/login`);
          return;
        }
        return;
      } else {
        const successMessage =
          getValueFromLanguageObject("str_add_to_wishlist_successfully") ||
          "Product added to wishlist successfully";
        this.loadAddToWishListMessageAnimation(successMessage);
      }
    } catch (error) {
      this.setState({ isLoading: false });
    }
  };

  handleClickOfQuickView = (product) => {
    this.setState({ product }, () => {
      this.initSingleCategry(product);
      this.showQuickViewProductDetailsModal();
    });
  };

  render() {
    const {
      isDrawerVisible,
      isLoading,
      goldPrice,
      AppTitle,
      currentCatelogueType,
      showErrorMessageModal,
      errorMessage,
      productsNewArrival,
      productsBestSelling,
      showAddToCartMessageModal,
      showAddToWishListMessageModal,
      addToCartMessage,
      addToWishListMessage,
      isQuickViewProductDetailsModalShow,
      category,
      product,
    } = this.state;
    const { symbol, isInquiry, masterDetailsObj } = this.props;
    return (
      <>
        <div className="">
          <NotificationContainer />
          <Helmet>
            <title>
              {getValueFromLanguageObject("str_welcome_to") || "Welcome To"}{" "}
              {AppTitle}
            </title>
          </Helmet>
          <Header
            handleNotificicationDrawer={this.handleNotificicationDrawer}
            onClickOnSettingsIcon={this.onClickOnSettingsIcon}
          />
          <section className="p-0 padding-bottom-cls">
            {this.renderOfferBanners()}
          </section>

          <Drawer
            anchor="right"
            open={isDrawerVisible}
            onClose={this.onCloseofNotificationDrawer}
          >
            <h3 style={notificationHeaderStyle}>
              {isMobile && (
                <i
                  onClick={() => {
                    this.onCloseofNotificationDrawer();
                  }}
                  style={{ padding: "10px" }}
                  className="fa fa-arrow-left"
                  aria-hidden="true"
                />
              )}
              {getValueFromLanguageObject("str_notification") ||
                "Notifications"}
            </h3>
            <NotificationList closeDrawer={this.onCloseofNotificationDrawer} />
          </Drawer>

          <div
            className="layout-8-bg"
            style={{
              backgroundImage: `url(${
                process.env.PUBLIC_URL
              }/assets/images/electronics/bg.jpg)`,
            }}
          >
            {/* <section className="banner-goggles ratio2_3">
            <div className="container-fluid">
              <div className="row partition3">
                {this.state.productsFeature
                  .slice(0, 3)
                  .map((product, index) => (
                    <div
                      key={index}
                      className="col-md-4"
                      onClick={() =>
                        this.props.history.push(`/singleproduct/${product.id}`)
                      }
                    >
                      <div className="collection-banner">
                        <div className="img-part">
                          <img
                            src={
                              product.productmedia.length > 0
                                ? getImageUrl(
                                    product.productmedia[0].productname
                                  )
                                : getPlaceHolderImage()
                            }
                            className="img-fluid blur-up lazyload bg-img"
                            alt=""
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src =
                                getPlaceHolderImage();
                            }}
                          />
                        </div>
                        <div className="contain-banner banner-3">
                          <div>
                            <h4>{product.name}</h4>
                            <h2>
                              {"₹"}
                              {product.price}
                            </h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

                {this.state.productsFeature.length === 0 && (
                  <div className="col-md-4">
                    <ContentLoaderMain />
                  </div>
                )}
              </div>
            </div>
          </section> */}

            {currentCatelogueType &&
              currentCatelogueType.registerallow === "Catalogue_Jewellary" && (
                <section className="banner-goggles ratio2_3 fixed-price">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-2">&nbsp;</div>
                      <div className="col-md-8">
                        <h3 className="title text-center">
                          Today's Gold/Silver Rate
                        </h3>

                        <div className="row">
                          {goldPrice &&
                            goldPrice.length > 0 &&
                            goldPrice.map((priceTag) => {
                              return (
                                <div className="col-md-4">
                                  <h4 className="current-price">
                                    <b>
                                      {symbol}
                                      {parseFloat(priceTag.price).toFixed(2)}
                                    </b>
                                    {priceTag.name}
                                  </h4>
                                </div>
                              );
                            })}
                        </div>
                        <div className="col-md-2">&nbsp;</div>
                      </div>
                    </div>
                  </div>
                </section>
              )}

            <SpecialProducts
              productsNewArrival={productsNewArrival}
              productsBestSelling={productsBestSelling}
              addToCart={this.addToCart}
              onAddToWishlistClicked={this.onAddToWishlistClicked}
              handleClickOfQuickView={this.handleClickOfQuickView}
              masterDetailsObj={masterDetailsObj}
            />
          </div>

          <div className="footer-white">
            <MainFooter />
          </div>
          <ThemeSettings />
        </div>
        {isLoading && <MainLoader />}
        {showErrorMessageModal && (
          <ErrorMessageAnimationModal
            show={showErrorMessageModal}
            message={errorMessage}
            onClose={() => {
              this.setState({ showErrorMessageModal: false });
            }}
          />
        )}
        {showAddToCartMessageModal && (
          <AddToCartAnim
            show={showAddToCartMessageModal}
            message={addToCartMessage}
            onClose={() => {
              this.setState({ showAddToCartMessageModal: false });
            }}
          />
        )}

        {showAddToWishListMessageModal && (
          <AddToWishListAnim
            show={showAddToWishListMessageModal}
            message={addToWishListMessage}
            onClose={() => {
              this.setState({ showAddToWishListMessageModal: false });
            }}
          />
        )}

        {isQuickViewProductDetailsModalShow && (
          <QuickViewProductDetailsModal
            product={product}
            category={category}
            masterDetailsObj={masterDetailsObj}
            symbol={symbol}
            isInquiry={isInquiry}
            onAddToCartClicked={this.addToCart}
            isQuickViewProductDetailsModalShow={
              isQuickViewProductDetailsModalShow
            }
            handleCloseQuickViewProductDetailsModal={
              this.hideQuickViewProductDetailsModal
            }
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  symbol: getCurrencySymbol(),
  isInquiry: state.settings.isInquiry,
  wishlist: state.settings.wishlist,
});

export default connect(
  mapStateToProps,
  {
    counter: getCommonCounter,
    notificationCounter: getNotificationCounter,
    isInquiryOrCart: isInquiryOrCart,
    updateQuantity: getUpdateQuantity,
    getWishlist: getWishlist,
  }
)(withRouter(Dashboard));
