import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import Modal from "react-responsive-modal";
import EllipsisText from "react-ellipsis-text";
import { getCall } from "../../../api/post";
import { connect } from "react-redux";
import {
  getImageUrl,
  getPlaceHolderImage,
  getMasterDetailsIdUrl,
  getBarcodeSetting,
} from "../../../services";
import { isInquiryOrCart } from "../../../actions";
import {
  getValueFromLanguageObject,
  getCurrencySymbol,
  getColorCodeBasedOnKey,
  loadUserDetailsFromLocalStorage,
  getOpenStoreIdApp,
} from "../../../store";
import ErrorMessageAnimationModal from "../../../animation/ErrorMessageAnimationModal";
import {
  clearLocalStorageReload,
  isOpenStoreIdExist,
  navigateTo,
  removeUserDetailAndOtherDetails,
} from "../../../utils/utils";
import Lightbox from "react-image-lightbox";

const imageContainer = {
  height: "100%",
  width: "100%",
  objectFit: "cover",
  border: "1px solid #D3D3D3",
};

class ProductListItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      stock:
        props.product.availablequantity > 0
          ? getValueFromLanguageObject("str_instock")
            ? getValueFromLanguageObject("str_instock")
            : "InStock"
          : getValueFromLanguageObject("str_out_of_stock")
          ? getValueFromLanguageObject("str_out_of_stock")
          : "Out of Stock !",
      quantity:
        props.product.minimumorderquantity >= 1
          ? props.product.minimumorderquantity
          : 1,
      image: "",
      category: {},
      pincode: "",
      showErrorMessageModal: false,
      errorMessage: "",
      masterDetailsObj: {},
      isFullScreenImage: false,
      fullScreenImagePath: "",
    };
  }

  componentDidMount() {
    this.initMasterDetailsId().then(() => {
      const { masterDetailsObj } = this.state;
      this.initBarcodeSettings();
      this.props.isInquiryOrCart(masterDetailsObj);
      this.initSingleCategry();
    });
  }

  initMasterDetailsId = async () => {
    let masterDetailsObj = {};
    const publicUrl = window.location.pathname.split("/")[1];
    const masterDetailsIdUrl = getMasterDetailsIdUrl(publicUrl);
    const masterDetails = await getCall(masterDetailsIdUrl, []).then();
    if (masterDetails.error) {
      clearLocalStorageReload({ response: masterDetails });
    } else {
      if (masterDetails) {
        masterDetailsObj = masterDetails;
      }
    }
    this.setState({ masterDetailsObj: masterDetailsObj });
  };

  initBarcodeSettings = async () => {
    const { masterDetailsObj } = this.state;
    const barcodeDetails = await getCall(
      getBarcodeSetting(masterDetailsObj),
      []
    );
    if (barcodeDetails.error) {
      clearLocalStorageReload({ response: barcodeDetails });
      this.loadErrorMessageAnimation(barcodeDetails.error.message);
    } else {
      if (barcodeDetails.length) {
        this.setState({
          barcodeDetails: barcodeDetails[0],
          isBarcode: barcodeDetails[0].status == 1 ? true : false,
        });
      }
    }
  };

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  onClickHandle(img) {
    this.setState({ image: img });
  }

  minusQty = () => {
    if (this.state.quantity > this.props.product.minimumorderquantity) {
      this.setState({
        stock: getValueFromLanguageObject("str_instock")
          ? getValueFromLanguageObject("str_instock")
          : "InStock",
      });
      this.setState({ quantity: this.state.quantity - 1 });
    }
  };

  plusQty = () => {
    if (this.props.product.availablequantity > this.state.quantity) {
      this.setState({ quantity: this.state.quantity + 1 });
    } else {
      this.setState({
        stock: getValueFromLanguageObject("str_out_of_stock")
          ? getValueFromLanguageObject("str_out_of_stock")
          : "Out of Stock!",
      });
    }
  };

  changeQty = (e) => {
    const { isInquiry, product } = this.props;
    if (!isInquiry && product.availablequantity === 0) {
      return;
    }
    if (e.target.value === "" || e.target.value === "-") {
      this.setState({ quantity: "" });
      return;
    }
    if (e.target.value < 1) {
      return;
    }
    try {
      this.setState({ quantity: parseInt(e.target.value) });
    } catch (error) {
      this.setState({ quantity: "" });
    }
  };

  initSingleCategry = async () => {
    const { product } = this.props || {};
    const { category } = product || {};
    const { parentId } = category || {};
    if (parentId) {
      const singleCategoryUrl = `categories/${parentId}`;
      const singleCategory = await getCall(singleCategoryUrl, []);
      if (singleCategory.error) {
        clearLocalStorageReload({ response: singleCategory });
        this.loadErrorMessageAnimation(singleCategory.error.message);
        if (singleCategory.error.statusCode === 401) {
          removeUserDetailAndOtherDetails();
          navigateTo(this.props, `/login`);
          return;
        }
      } else {
        this.setState({ category: singleCategory });
      }
    }
  };

  getCartListCounter = async () => {
    let cartCounter = 0;
    let userDetails = loadUserDetailsFromLocalStorage();
    if (!isOpenStoreIdExist() && userDetails === undefined) {
      return;
    } else {
      userDetails = userDetails === undefined ? {} : userDetails;
    }
    const { id } = userDetails;
    const openStoreId = getOpenStoreIdApp();
    let userId = openStoreId ? openStoreId : id;
    const counterUrl = "commoncounters?filter[where][userId]=" + userId;
    const commonCounter = await getCall(counterUrl, []);
    if (commonCounter.error) {
      clearLocalStorageReload({ response: commonCounter });
      this.loadErrorMessageAnimation(commonCounter.error.message);
    } else {
      if (commonCounter.length > 0) {
        cartCounter = commonCounter[0].cart;
      }
    }
    return cartCounter;
  };

  pincodeHandleSubmit = (event) => {
    this.setState({ pincode: event.target.value });
  };

  loadErrorMessageAnimation = (message) => {
    this.setState({ errorMessage: message, showErrorMessageModal: true });
  };

  render() {
    const {
      product,
      symbol,
      onAddToCartClicked,
      onAddToWishlistClicked,
      isInquiry,
      handleClickOfQuickView,
    } = this.props;

    const {
      showErrorMessageModal,
      errorMessage,
      masterDetailsObj,
      isFullScreenImage,
      fullScreenImagePath,
      isBarcode,
    } = this.state;

    let RatingStars = [];
    for (var i = 0; i < product.rating; i++) {
      RatingStars.push(<i className="fa fa-star" key={i} />);
    }

    const strOutOfStock =
      getValueFromLanguageObject("str_out_of_stock") || "Out of Stock";

    const strThereAre =
      getValueFromLanguageObject("str_there_are") || "There are";

    const strUnitsOfItemAlreadyInTheCart =
      getValueFromLanguageObject("str_units_of_item_already_in_the_cart") ||
      "units of item already in the cart";

    const strAddToWishList =
      getValueFromLanguageObject("str_add_to_wishlist") || "Add to wishlist";

    return (
      <>
        <div className="product-box">
          <div className="img-wrapper mb-2">
            <div className="front">
              <Link to={`/singleproduct/${product.id}`}>
                <div style={{ height: "250px" }}>
                  <img
                    style={imageContainer}
                    src={
                      product.productmedia.length > 0
                        ? getImageUrl(
                            product.productmedia[0].productname,
                            masterDetailsObj
                          )
                        : getPlaceHolderImage()
                    }
                    // className="img-fluid"
                    alt=""
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = getPlaceHolderImage();
                    }}
                  />
                </div>
              </Link>
            </div>
            <div className="cart-info cart-wrap">
              <button
                data-toggle="modal"
                data-target="#quick-view"
                title={
                  getValueFromLanguageObject("str_quick_view")
                    ? getValueFromLanguageObject("str_quick_view")
                    : "Quick View"
                }
                // onClick={this.onOpenModal}
                onClick={() => handleClickOfQuickView(product)}
                className="cursor-pointer"
              >
                <i className="fa fa-eye" aria-hidden="true" />
              </button>
              <button
                title={
                  getValueFromLanguageObject("str_view_image")
                    ? getValueFromLanguageObject("str_view_image")
                    : "View Image"
                }
                onClick={() => {
                  const path =
                    product.productmedia.length > 0
                      ? getImageUrl(
                          product.productmedia[0].productname,
                          masterDetailsObj
                        )
                      : getPlaceHolderImage();
                  this.setState({
                    isFullScreenImage: true,
                    fullScreenImagePath: path,
                  });
                }}
              >
                <i className="fa fa-search-plus" aria-hidden="true" />
              </button>
              <button
                title={
                  isInquiry
                    ? getValueFromLanguageObject("str_add_to_inquiry")
                      ? getValueFromLanguageObject("str_add_to_inquiry")
                      : "Add To Inquiry"
                    : getValueFromLanguageObject("str_add_to_cart")
                    ? getValueFromLanguageObject("str_add_to_cart")
                    : "Add to cart"
                }
                onClick={() => {
                  onAddToCartClicked(product, this.state.quantity, isInquiry);
                }}
                disabled={
                  !isInquiry && product && product.availablequantity === 0
                    ? true
                    : false
                }
              >
                <i className="fa fa-shopping-cart" aria-hidden="true" />
              </button>
              <button
                title={strAddToWishList}
                onClick={async () => {
                  const userDetails = loadUserDetailsFromLocalStorage();
                  if (!userDetails) {
                    navigateTo(this.props, `/login/isFromDashboard=${true}`);
                    return;
                  }
                  onAddToWishlistClicked(product);
                }}
              >
                <i className="fa fa-heart" aria-hidden="true" />
              </button>
            </div>
          </div>
          <div className="product-detail">
            <div>
              <Link to={`/singleproduct/${product.id}`}>
                <h6
                  style={{
                    color: getColorCodeBasedOnKey("text_product_name_products"),
                  }}
                >
                  <EllipsisText text={product.name} length={35} />
                </h6>
              </Link>
              {isBarcode && product.barcodeno && <h6>{product.barcodeno}</h6>}

              {product && product.category && product.category.name && (
                <h6>
                  <EllipsisText text={product.category.name} length={20} />
                </h6>
              )}

              {!isInquiry && (
                <h4>{`${symbol} ${
                  product.price === null
                    ? "0"
                    : parseFloat(product.price).toFixed(2)
                }`}</h4>
              )}

              {product && product.totalCartCounter > 0 && (
                <div className="quantity-cart-item">
                  {`${strThereAre} ${product.totalCartCounter} ${strUnitsOfItemAlreadyInTheCart}`}
                </div>
              )}

              <div>
                {!isInquiry && product && product.availablequantity === 0 && (
                  <div className="product-outofstock">{strOutOfStock}</div>
                )}
              </div>
            </div>
          </div>
          {showErrorMessageModal && (
            <ErrorMessageAnimationModal
              show={showErrorMessageModal}
              message={errorMessage}
              onClose={() => {
                this.setState({ showErrorMessageModal: false });
              }}
            />
          )}
        </div>
        {isFullScreenImage && (
          <Lightbox
            mainSrc={fullScreenImagePath}
            onCloseRequest={() =>
              this.setState({
                isFullScreenImage: false,
                fullScreenImagePath: "",
              })
            }
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  isInquiry: state.settings.isInquiry,
  symbol: getCurrencySymbol(),
});

export default connect(mapStateToProps, { isInquiryOrCart: isInquiryOrCart })(
  withRouter(ProductListItem)
);
