import React, { Component } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import Modal from "react-responsive-modal";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import ErrorMessageAnimationModal from "../../animation/ErrorMessageAnimationModal";
import MainLoader from "../../animation/MainLoader";
import SuccessMessageAnimationModal from "../../animation/SuccessMessageAnimationModal";
import { postCall } from "../../api/post";

import {
  getValueFromLanguageObject,
  loadUserDetailsFromLocalStorage,
} from "../../store";
import { clearLocalStorageReload, getWishListName } from "../../utils/utils";

class ShareWishListModal extends Component {
  state = {
    wishListName: "",
    showSuccessMessageModal: "",
    successMessage: "",
    showErrorMessageModal: "",
    errorMessage: "",
    isLoading: false,
    shareUrl: "",
  };

  componentDidMount() {
    const wishListName = getWishListName(this.props.wishlist);
    this.setState({ wishListName, isLoading: true });
    this.initShareLink().then(() => {
      this.setState({ isLoading: false });
    });
  }

  initShareLink = async () => {
    const userDetails = loadUserDetailsFromLocalStorage();
    const { id } = userDetails || {};
    const shareLinkUrl = "products/shortnewURL";
    const link = window.location.href.replace("wishlist", "shared-wishlist");
    const shareLinkObj = {
      url: `${link}/${id}`,
    };
    const shareLinkResponse = await postCall(shareLinkUrl, shareLinkObj);
    if (shareLinkResponse.error) {
      clearLocalStorageReload({ response: shareLinkResponse });
      this.loadErrorMessageAnimation(shareLinkResponse.error.message);
    } else {
      this.setState({ shareUrl: shareLinkResponse.shortUrl });
    }
  };

  loadErrorMessageAnimation = (message) => {
    this.setState({ errorMessage: message, showErrorMessageModal: true });
  };

  loadSuccessMessageAnimation = (message) => {
    this.setState({ successMessage: message, showSuccessMessageModal: true });
  };

  render() {
    const {
      showSuccessMessageModal,
      successMessage,
      showErrorMessageModal,
      errorMessage,
      isLoading,
      shareUrl,
    } = this.state;

    const { isShareWishListOpen, onCloseShareWishListModal } = this.props;

    const strShareWishlistTo =
      getValueFromLanguageObject("str_share_wishlist_to") ||
      "Share Wishlist To";

    const strShareWishlist =
      getValueFromLanguageObject("str_share_wishlist") || "Share Wishlist";

    const strWishlistLinkCopiedToClipboard =
      getValueFromLanguageObject("str_wishlist_link_copied_to_clipboard") ||
      "Wishlist link copied to clipboard";

    return (
      <>
        <Modal
          open={isShareWishListOpen}
          onClose={onCloseShareWishListModal}
          classNames={{
            overlay: "customOverlay",
            modal: "customModal",
          }}
          center
        >
          <div className="wishlist-title">
            <h3 className="text-dark">{strShareWishlist}</h3>
          </div>
          <div className="theme-form" style={{ margin: "20px" }}>
            <div className="border-product">
              <h6 className="product-title">{strShareWishlistTo}</h6>
              <div className="product-icon">
                <ul className="product-social">
                  <li>
                    <FacebookShareButton url={shareUrl} quote={""}>
                      <FacebookIcon size={42} round />
                    </FacebookShareButton>
                  </li>

                  <li>
                    <TwitterShareButton url={shareUrl} via={""}>
                      <TwitterIcon size={42} round />
                    </TwitterShareButton>
                  </li>

                  <li>
                    <TelegramShareButton url={shareUrl} title={""}>
                      <TelegramIcon size={42} round />
                    </TelegramShareButton>
                  </li>

                  <li>
                    <WhatsappShareButton
                      url={shareUrl}
                      title={""}
                      separator=" :: "
                    >
                      <WhatsappIcon size={42} round />
                    </WhatsappShareButton>
                  </li>

                  <li>
                    <LinkedinShareButton url={shareUrl} summary={""}>
                      <LinkedinIcon size={42} round />
                    </LinkedinShareButton>
                  </li>

                  <li>
                    <EmailShareButton
                      url={shareUrl}
                      subject={"Wishist"}
                      body={shareUrl}
                      formTarget="_"
                    >
                      <EmailIcon size={42} round />
                    </EmailShareButton>
                  </li>
                </ul>
              </div>
              <div style={{ display: "flex", marginTop: "5px" }}>
                <input
                  type="text"
                  disabled
                  name="shareInput"
                  value={shareUrl}
                  className="form-control"
                />

                <CopyToClipboard
                  text={shareUrl}
                  onCopy={() => {
                    this.loadSuccessMessageAnimation(
                      strWishlistLinkCopiedToClipboard
                    );
                  }}
                >
                  <div className="btn btn-solid">
                    {getValueFromLanguageObject("str_copy") || "Copy"}
                  </div>
                </CopyToClipboard>
              </div>
            </div>
          </div>
        </Modal>
        {isLoading && <MainLoader />}
        {showErrorMessageModal && (
          <ErrorMessageAnimationModal
            show={showErrorMessageModal}
            message={errorMessage}
            onClose={() => {
              this.setState({ showErrorMessageModal: false });
            }}
          />
        )}
        {showSuccessMessageModal && (
          <SuccessMessageAnimationModal
            show={showSuccessMessageModal}
            message={successMessage}
            onClose={() => {
              this.setState({ showSuccessMessageModal: false });
            }}
          />
        )}
      </>
    );
  }
}

export default ShareWishListModal;
